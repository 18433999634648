// actionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface State {
  action: any;
}

const initialState: State = {
  action: '',
};

const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    setAction(state, action: PayloadAction<any>) {
      state.action = action.payload;
    },
  },
});

export const { setAction } = actionSlice.actions;
export const action = (state: RootState) => state.action.action
export default actionSlice.reducer;
