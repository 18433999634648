import request from './requesters/zoho.request';
const zohoService = {
  testGet: (id: string) => request.get('/'),
  getContact: (id: string) => request.get('/get-contact-zoho/' + id),
  getContactCRM: (id: string) => request.get(`/zoho-contact-CRM/${id}`),
  createEstimate: (data: any) => request.post('/zoho-create-estimate', data),
  validateAndCreateOrder: (data: any) => request.post('/zoho-validate-and-create-order', data),
  updateEstimate: (estimateId: string, data: any) =>
    request.put(`/zoho-estimate/${estimateId}`, data),
  getEstimate: (id: string) => request.get('/zoho-estimate/' + id),

  createOrder: (data: any) => request.post('/zoho-create-salesorders', data),
  updateOrder: (id: string, data: any) => request.put(`/zoho-salesorders/${id}`, data),
  getOrder: (id: string) => request.get('/zoho-salesorders/' + id),
  createContact: (data: any) => request.post('/zoho-contact-CRM', data),
  updateContact: (id: string, data: any) => request.put(`/zoho-contact-CRM/${id}`, data),
  createDemander: (data: any) => request.post('/zoho-create-demander', data),
  createAccount: (data: any) => request.post('/zoho-account-CRM', data),
  getDataClientPayment: (id: string) => request.get(`/zoho-data-client-payment/${id}`),
  getDataClientPaymentWithCompany: (id: string) => request.get(`/zoho-data-client-payment-with-company/${id}`),
  updateSubStatusOrder: (data: any) => request.post('/zoho-update-sub-status-order', data),
  getListZohoBookContactPerson: (fullSearch: string) => request.get(`/get-list-zoho-book-contact-person?fullSearch=${fullSearch}`),
  getListZohoBookContactPersons: (param: any) => request.get(`/get-list-zoho-book-contact-person?${param}`),
  deleteFileToSalesorder: (salesorder_id: string, document_id: any) => request.post(`/delete-file-to-salesorder/${salesorder_id}`, {document_id}),
  uploadFileToSalesorder: (salesorder_id: string, file_upload: any[]) => request.post(`/add-file-to-salesorder/${salesorder_id}`, { file_upload: file_upload }),
  getListCompany: (name_company: any, code_postal:any) => request.get(`/pappers-search-list-company?name_company=${name_company}&code_postal=${code_postal}`),
  getDetailCompany: (siren: any) => request.get(`/pappers-get-company-detal?siren=${siren}`),
};

export default zohoService;
