import { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Space,
  Modal,
  Table,
  Pagination,
  PaginationProps,
  Flex,
  Typography,
  Tag,
  Form,
  Col,
  Row,
  Select,
  Input,
  Radio,
  RadioChangeEvent,
  Divider,
} from 'antd';
import Search from "antd/es/input/Search";
import type { SearchProps } from 'antd/es/input/Search';
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { zohoService } from 'services';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {CREATE_ACCOUNT, CONTACT_TYPES, LIST_SERVICE} from "../utils/constant";
import { frenchCurrencyFormat } from '../utils';
import SelectWithGoogleSuggestion from './common/SelectWithGoogleSuggestion';
import { Address } from 'types';
import { useDebounce } from 'hooks';
import SearchCompany from './SearchCompany';

const { Text } = Typography;
type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface SearchClientProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSelectClient: (value: any) => void;
  contact?: any;
  lastModifyById?: string | null;
  referentId?: string | null;
  getContact?: any;
  createAccount?: any;
  setIsChangeClient: (value: boolean) => void;
  setChangedClient: (value: any) => void;
}

interface PaginationSearch {
  page: string;
  pageSize: string;
}

const SearchClient = (props: SearchClientProps) => {
  const { open, setOpen, onSelectClient, contact, lastModifyById, referentId, getContact, createAccount, setIsChangeClient, setChangedClient } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [form] = Form.useForm();
  const [clientType, setClientType] = useState(CONTACT_TYPES.PROFESSIONNEL);
  const [isCreateNewAccount, setIsCreateNewAccount] = useState(createAccount == CREATE_ACCOUNT.CREATE_A_CONTACT || createAccount == CREATE_ACCOUNT.CREATE_CONTACT_ESTIMATE ? true : false);
  const [isSubmitCreateNewAccountOrContact, setIsSubmitCreateNewAccountOrContact] = useState(false);
  const [addressFacturation, setAddressFacturation] = useState<Address[]>([]);
  const [selectAddressFacturation, setSelectAddressFacturation] = useState<any>({});
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchValueObject, setSearchValueObject] = useState<any>();
  const [dataCompany, setDataCompany] = useState<any>(null);
  const [showModalPaper, setShowModalPaper] = useState(false);
  const [isUpdateAnything, setIsUpdateAnything] = useState(false)
  const [companyDetail, setCompanyDetail] = useState<any>(null)
  const [selectCompany, setSelectCompany] = useState<boolean>(false)
  const [step2, setStep2] = useState<any>(false)

  const initialValues = () => {
    setSearchValueObject(null);
    setSearchValue('');
    setDataSource([]);
    setClientType(CONTACT_TYPES.PROFESSIONNEL);
    setPagination({
      page: 1,
      pageSize: 10,
      total: 0,
    });
    form.resetFields();
    setSelectAddressFacturation(null);
    form.setFieldValue('address_facturation', null);
    setChangedClient(null);
  }

  const onChangeClientType = (e: RadioChangeEvent) => {
    setSelectCompany(false);
    setClientType(e.target.value);
    clientTypeCurrent = e.target.value;
  };
  const onSelectCompany = async(value: any) => {
    try {
      if(value){
        setIsLoading(true);
        await zohoService.getDetailCompany(value.siren).then((response : any) => {
          if(response.success && response?.company){
            setCompanyDetail(response?.company)
            form.setFieldsValue({
              company_name: response?.company?.nom_entreprise,
              cf_siren: response?.company?.siren,
            })
            setSearchValueObject({
              ...searchValueObject,
              company_name: response?.company?.nom_entreprise,
              cf_siren: response?.company?.siren,
            })
            setSelectCompany(true)
            setDataCompany(null)
            setShowModalPaper(false)
            setIsUpdateAnything(false)
          }
        })
      }
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
    }
  }
  const handleCreateNewContactOrAccount = async()=>{
    try {
      const vaild = await form.validateFields();
      if(!vaild) return;
      setIsLoading(true);
      setIsSubmitCreateNewAccountOrContact(true);
      const values = form.getFieldsValue();
      let submitData : any = {
        First_Name: values.first_name,
        Last_Name: values.last_name,
        Full_Name: `${values.first_name} ${values.last_name}`,
        Owner: lastModifyById,
        Phone: values.phone,
        Email: values.email,
      };
      if(clientType === CONTACT_TYPES.PROFESSIONNEL){
        submitData.Created_By = lastModifyById;
        submitData.Modified_By = lastModifyById;
        submitData.N_SIREN_NEW = values.cf_siren;
        submitData.Account_Name = values.company_name;
        if(selectAddressFacturation?.address){
          submitData.Billing_Street = values.address_facturation?.address;
          submitData.Billing_City = values.address_facturation?.city;
          submitData.Billing_Country = values.address_facturation?.country;
          submitData.Billing_Code = values.address_facturation?.zip;
        }
        let submitDataWithCompany: any = {
          ...submitData,
          latitude_de_facturation: values.address_facturation?.latitude,
          longitude_de_facturation: values.address_facturation?.longitude
        }
        if(companyDetail){
          submitDataWithCompany = {
            ...submitDataWithCompany,
            data_company: companyDetail,
            libelle_code_naf: companyDetail?.libelle_code_naf,
            domaine_activite: companyDetail?.domaine_activite,
            forme_juridique: companyDetail?.forme_juridique,
            effectif: companyDetail?.effectif,
            objet_social: companyDetail?.objet_social
          }
        }
        const res: any = await zohoService.createAccount(submitDataWithCompany);
        if (res?.data?.error === true || res.success === false) {
          toast.error(`Erreur innatendue à la création du compte / contact, merci d'alerter l'équipe technique: dev.alertes@ecodrop.net`);
          throw res;
        }
        submitData.Account_Name = res?.data?.data[0]?.details?.id;
        submitData.zcrm_account_id = res?.zcrm_account_id;
        submitData.clientType = CONTACT_TYPES.PROFESSIONNEL;
        submitData.service = values.service;
      }
      else if(clientType === CONTACT_TYPES.INDIVIDUEL){
        submitData.Mailing_Street = values.address_facturation?.address;
        submitData.Mailing_City = values.address_facturation?.city;
        submitData.Mailing_Country = values.address_facturation?.country;
        submitData.Mailing_Zip = values.address_facturation?.zip;
        
      }
      const res: any = await zohoService.createContact(submitData);
      if (res?.data?.error === true || res.success === false) {
        toast.error(`Erreur innatendue à la création du compte / contact, merci d'alerter l'équipe technique: dev.alertes@ecodrop.net`);
        throw res;
      }
      if(res?.data?.data[0]){
        if(createAccount == CREATE_ACCOUNT.CREATE_A_CONTACT) {
          window.location.href = getlinkZoho(res?.data?.data[0]?.details?.id);
        } else {
          setIsChangeClient(true);
          await getContact(res?.data?.data[0]?.details?.id);
          setOpen(false);
          setIsCreateNewAccount(false);
        }
      }
      setIsLoading(false);
      setIsSubmitCreateNewAccountOrContact(false);
      setCompanyDetail(null);
      setStep2(false);
      setSelectCompany(false);
    } catch (error) {
      setIsLoading(false);
      setIsSubmitCreateNewAccountOrContact(false);
      setCompanyDetail(null);
      setStep2(false);
      setSelectCompany(false);
      console.error('Validation error:', error);
    }
  }
  const getlinkZoho = (redirectId : string) => {
    return (process.env.REACT_APP_ZOHO_PATH + `/${redirectId}/canvas/465671000038692869`);
  }
  const searchCompany = async(type?:string)=>{
    try {
      if(searchValueObject?.company_name && clientType === 'professionnel'){
        setIsLoading(true);
        const listCompanyInformation = await zohoService.getListCompany(searchValueObject?.company_name, selectAddressFacturation?.zip ? selectAddressFacturation?.zip : '');
        if(listCompanyInformation){
          setDataCompany(listCompanyInformation);
          switch(type){
            case 'Nouvelle recherche':
              setShowModalPaper(true)
              setStep2(true)
              setIsUpdateAnything(false)
              break;
            default:
              if(listCompanyInformation?.list_company?.resultats?.length > 0){
                setShowModalPaper(true)
                setStep2(true)
                setIsUpdateAnything(false)
              }
              else{
                setShowModalPaper(false)
                handleCreateNewContactOrAccount();
              }
          }
        }
      }
      else if(clientType === 'individuel'){
        handleCreateNewContactOrAccount();
      }
    } catch (error) {
      setIsLoading(false);
    }
    finally{
      setIsLoading(false);
    }
  }
  const handleCreateNewAccount = () => {
    setIsCreateNewAccount(false);
  };

  useEffect(() => {
    if (open) {
      initialValues();
      onSearchSearchField?.(searchValue);
    }
  }, [open]);

  const handleCancel = () => {
    setSelectCompany(false);
    setStep2(false);
    setIsCreateNewAccount(false);
    setOpen(false);
  };

  let clientTypeCurrent = CONTACT_TYPES.PROFESSIONNEL ;

  const searchClientWithParams = useDebounce( async () => {
    setIsLoading(true);
    setIsUpdateAnything(true);
    setShowModalPaper(false);
    const requestParam = {
      ...searchValueObject,
      page: pagination.page.toString(),
      pageSize: pagination.pageSize.toString(),
    }
    const valuesSearch = form.getFieldsValue();
    const { first_name, last_name, phone, email, address_facturation, company_name, cf_siren } = valuesSearch;
    const shouldShowTableResultSearch = [ first_name, last_name, phone, email, address_facturation, clientTypeCurrent === CONTACT_TYPES.PROFESSIONNEL && (company_name || cf_siren) ].some(value => value !== undefined && value !== '' && value !== null);
    if(shouldShowTableResultSearch || searchValue != ''){
        const paramsString = new URLSearchParams(requestParam).toString();
        const { zohoBookContactPerson } = await zohoService.getListZohoBookContactPersons(paramsString);
        setPagination({
            ...pagination,
            total: zohoBookContactPerson?.total,
          }
        );
        if(zohoBookContactPerson?.data.length === 0 && companyDetail && selectCompany){
          setDataSource([]);
          handleCreateNewContactOrAccount();
        }
        else{
          const dataWithUUID = zohoBookContactPerson?.data.map((item: any) => ({
            ...item,
            uuid: uuidv4(),
          }));
          setDataSource(dataWithUUID);
        }
    } else {
      setDataSource([]);
      setPagination({
        page: 1,
        pageSize: 10,
        total: 0,
      });
    }
    setIsLoading(false);
  },  500);

  const onSearchSearchField: SearchProps['onSearch'] = async (value, _e, info) => {
    setIsLoading(true);
    try {
      setSearchValueObject({
        fullSearch: value,
      })
      setIsLoading(false);
    } catch (error) {
      toast.error('Erreur');
      setIsLoading(false);
    }
  };

  const handleRowClick = (record: any) => {
    setSelectCompany(false);
    setStep2(false);
    setIsLoading(true);
    setIsCreateNewAccount(false);
    onSelectClient(record);
  }

  useEffect(() => {
    searchClientWithParams();
  }, [searchValueObject, pagination.page, pagination.pageSize]);
  useEffect(() => {
    setSearchValueObject({
      fullSearch: searchValue,
    })
  }, [searchValue]);

  const onChangePage : PaginationProps['onChange'] = async (page: number, pageSize: number) => {
    setPagination({
      page: page,
      pageSize: pageSize,
      total: pagination.total,
    })
  }

  const onSearchCreateNewAccountFields = async (_: any, allFields: any) => {
    setSelectCompany(false);
    debouncedSearch(allFields);
  };
  // Custom debounce function
  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce(async (allFields: any) => {
      setIsLoading(true);
      try {
        // Filter out empty or undefined fields
        const filteredValues = Object.keys(allFields).reduce((acc: any, key) => {
          if (allFields[key] !== undefined && allFields[key] !== '') {
            acc[key] = allFields[key];
          }
          return acc;
        }, {});
        setSearchValueObject(filteredValues);
        setIsLoading(false);
      } catch(error) {
        toast.error('Erreur');
        setIsLoading(false);
      }
    }, 500), // The debounce delay (in milliseconds)
    []
  );
  const handleSelectFacturationAddress = async (address: Address) => {
    setSelectCompany(false);
    setIsUpdateAnything(true);
    setSelectAddressFacturation(address);
    const existingAddress = addressFacturation.find((a) => a.address_id === address.address_id);
    if (!existingAddress) {
      setAddressFacturation((prevAddress) =>[...prevAddress, address] );
    }
    form.setFieldValue('address_facturation', address);
  };
  const columns = [
    {
      width: '12%',
      title: 'Société',
      dataIndex: 'company_name',
      render: (_: any, record: any) => {
        return (
          <div style={{ minWidth: '50px' }}>
            <Space direction='vertical' align='baseline'>
              <Text>
                {record.zoho_book_contact.company_name}
              </Text>
              <Text>
                {record.zoho_book_contact.cf_siren}
              </Text>
              {record.zoho_book_billing_addresses.map((billingAddress:any) => (
                <Text key={billingAddress.id}>{billingAddress.address ? `${billingAddress.address}, ` : ''}{billingAddress.zip} {billingAddress.city}</Text>
              ))}
            </Space>
          </div>
        );
      }
    },
    {
      width: '12%',
      title: 'Contact',
      dataIndex: 'zoho_book_contact_persons',
      render: (_: any, record: any) => {
        return (
          <div style={{ minWidth: '50px' }}>
            <Space direction='vertical' align='baseline'>
              <Text>
                {record.first_name} {record.last_name}
              </Text>
              <Text>
                {record.phone}
              </Text>
              <Text>
                {record.email}
              </Text>
            </Space>
          </div>
        );
      }
    },
    {
      width: '11%',
      title: 'Commercial',
      dataIndex: 'crm_contacts_owner_name',
      render: (_: any, record: any) => {
        return (
          <Text>
            {record.zoho_book_contact.crm_contacts_owner_name}
          </Text>
        );
      }
    },
    {
      width: '20%',
      title: 'Chantiers',
      dataIndex: 'zoho_c_r_m_deals',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.zoho_c_r_m_deals.map((deal: any) => (
              <div key={deal.id}>{deal.adresse_compl_te}</div>
            ))}
          </div>
        )
      }
    },
    {
      width: '10%',
      title: 'En compte',
      dataIndex: 'cf_en_compte',
      render: (_: any, record: any) => {
        {
          let enCompte = record.zoho_book_contact.cf_en_compte === 'En compte' ?
            (<Tag color='geekblue' key={record.zoho_book_contact.cf_en_compte}>
              {record.zoho_book_contact.cf_en_compte}
            </Tag>)
            :
            (
              record.zoho_book_contact.cf_en_compte === 'Paiement requis' ?
                (<Tag color='orange' key={record.zoho_book_contact.cf_en_compte}>
                  {record.zoho_book_contact.cf_en_compte}
                </Tag>) : null
            );

          return enCompte;
        }
      }
    },
    {
      width: '12%',
      title: 'Encours dispo',
      dataIndex: 'encours_dispo',
      render: (_: any, record: any) => {
        return (
          <div key={record.zoho_analytic?.id}>
            {record.zoho_analytic?.encours_dispo ? (
              <div>
                <Text style={{ lineHeight: '32px' }}>Dispo: {`${frenchCurrencyFormat(
                  (parseFloat(record.zoho_analytic?.encours_dispo) ?? 0).toFixed(2)
                )} €`}</Text>
              </div>
            ) : null}

            {record.zoho_analytic?.encours_aut ? (
              <div>
                <Text style={{ lineHeight: '32px' }}>Autori: {`${frenchCurrencyFormat(
                  (parseFloat(record.zoho_analytic?.encours_aut) ?? 0).toFixed(2)
                )} €`}</Text>
              </div>
            ) : null}
          </div>
        )
      }
    },
    {
      width: '10%',
      title: 'Type de compte',
      dataIndex: 'cf_type_de_compte',
      render: (_: any, record: any) => {
        return (
          <Text>
            {record.zoho_book_contact.cf_type_de_compte}
          </Text>
        );
      }
    },
    {
      width: '13%',
      title: 'Infos conso',
      dataIndex: 'customer_sub_type',
      fixed: 'right',
      render: (_: any, record: any) => {
        return (
          <div key={record.zoho_analytic?.id}>
            {record.zoho_analytic?.estimates_m ? (
              <div>
                <span>Devis (m-1): {record.zoho_analytic?.estimates_m}</span>
              </div>
            ) : null}

            {record.zoho_analytic?.sales ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ lineHeight: '32px' }}>Facturé année:</Text>
                <Text style={{ lineHeight: '32px' }}>{`${frenchCurrencyFormat(
                  (parseFloat(record.zoho_analytic?.sales) ?? 0).toFixed(2)
                )} €`}</Text>

              </div>
            ) : null}

            {record.zoho_analytic?.estimates ? (
              <div>
                <span>Nb devis: {record.zoho_analytic?.estimates}</span>
              </div>
            ) : null}

            {record.zoho_analytic?.orders ? (
              <div>
                <span>Nb commande: {record.zoho_analytic?.orders}</span>
              </div>
            ) : null}

            {record.zoho_analytic?.invoices ? (
              <div>
                <span>Nb facture: {record.zoho_analytic?.invoices}</span>
              </div>
            ) : null}
          </div>
        )
      }
    }
  ];

  return (
    <>
      <Modal
        title='Choisir un Compte'
        open={open}
        onCancel={handleCancel}
        centered
        width='90%'
        footer={[
        ]}>
        <Flex vertical justify="center">
          <Flex justify='center'
            style={{
              height: !isCreateNewAccount ? '32px' : '0px',
              transition: 'height 0.3s ease-in-out',
              overflow: 'hidden',
            }}>
            <Search
              placeholder='Rechercher'
              onSearch={onSearchSearchField}
              style={{ width: 400 }}
              loading={isLoading}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              type='primary'
              onClick={() => {
                initialValues();
                setIsCreateNewAccount(true);
              }}
              className='ant-modal-content__add-btn ml-12'>
              Nouveau Compte
            </Button>
          </Flex>
          <Flex
            justify="center"
            style={{
              height: isCreateNewAccount ? 'auto' : '0px',
              transition: 'height 0.3s ease-in-out',
              overflow: 'hidden',
            }}
          >
            <section className='my-4 section client-chantier px-4'>
              <Form
                layout='horizontal'
                form={form}
                className='mb-2'
                style={{ minHeight: '240px' }}
                onValuesChange={(changedValues, allValues) => {
                  onSearchCreateNewAccountFields(changedValues, allValues)
                }}
              >
                <Row gutter={[16, 0]}>
                  <Form.Item
                    label="Type de client"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      onChange={onChangeClientType}
                      value={clientType}
                    >
                      <Radio value={CONTACT_TYPES.PROFESSIONNEL}> Professionnel </Radio>
                      <Radio value={CONTACT_TYPES.INDIVIDUEL}> Individuel </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
                <Row gutter={80}>
                  <Col>
                    {clientType === CONTACT_TYPES.PROFESSIONNEL &&
                      <Form.Item
                        name="company_name"
                        label="Société"
                        rules={[{ required: true, message: 'Please input!' }]}
                      >
                        <Input />
                      </Form.Item>
                    }
                    <Form.Item
                      name="last_name"
                      label="Nom"
                      rules={[{ required: true, message: 'Please input!' }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Téléphone"
                      rules={[{ required: true, message: 'Please input!' }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="address_facturation"
                      label="Address Facturation"
                    >
                      <SelectWithGoogleSuggestion
                        defaultValue={selectAddressFacturation}
                        options={addressFacturation ?? []}
                        onSelect={handleSelectFacturationAddress}
                    />
                    </Form.Item>
                  </Col>
                  <Col>
                    {clientType === CONTACT_TYPES.PROFESSIONNEL &&
                      <Form.Item
                        name="cf_siren"
                        label="SIREN"
                        // rules={[{ required: true, message: 'Please input!' }]}
                      >
                        <Input />
                      </Form.Item>
                    }
                    <Form.Item
                      name="first_name"
                      label="Prénom"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ required: true, message: 'Please input!' }]}
                    >
                      <Input />
                    </Form.Item>
                    {clientType === CONTACT_TYPES.PROFESSIONNEL &&
                      <Form.Item
                        name="service"
                        label="Service"
                        rules={[{ required: true, message: 'Please select!' }]}
                      >
                        <Select
                          options={LIST_SERVICE?.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))}
                        />
                      </Form.Item>
                    }

                  </Col>
                </Row>
              </Form>
            </section>
          </Flex>  
            {
              showModalPaper && dataCompany
              ? (<>
                <Divider className='horizontal-bar my-4' />
                <SearchCompany company={dataCompany} onSelectCompany={onSelectCompany} isLoading={isLoading}/>
              </>)
              : (<>
                {
                  dataSource.length > 0 && (
                    <>
                      <Divider className='horizontal-bar my-4' />
                      <Typography.Title level={3} className='text-center font-italic font-weight-light'>Clients / Prospects</Typography.Title>
                      <SortableContext items={dataSource.map((item) => item.uuid)} strategy={verticalListSortingStrategy}>
                        <Table
                          scroll={{ x: '100%' }}
                          rowKey={'uuid'}
                          dataSource={dataSource}
                          columns={columns as ColumnTypes}
                          onRow={(record, rowIndex) => ({
                            onClick: () => handleRowClick(record),
                          })}
                          rowClassName='hover-cursor-pointer'
                          loading={isLoading}
                          pagination={false}
                        />
                      </SortableContext>
                      <Flex hidden={pagination.total === 0} justify="space-between" className='my-4'>
                        <Text className="font-italic font-weight-light" role="status" aria-live="polite">
                          Affichage de l'élement {(pagination?.page-1)*pagination?.pageSize+1} à {(pagination?.pageSize*pagination?.page) > pagination?.total ? pagination?.total : pagination?.pageSize*pagination?.page} sur {pagination?.total} éléments
                        </Text>
                        <Pagination
                          defaultCurrent={1}
                          total={pagination.total}
                          onChange={onChangePage}
                          current={pagination.page}
                        />
                      </Flex>
                    </>
                  )
                }
                
                </>)

            }
          <Flex style={{
            height: isCreateNewAccount ? '40px' : '0px',
            overflow: 'hidden',
          }}
                className="button_create_cancel_search_client">
            <Flex style={{ margin: '0 auto' }}>
                <Button
                  key='annuler'
                  onClick={() => {
                    setIsCreateNewAccount(false);
                    form.resetFields();
                    setStep2(false);
                    setDataSource([]);
                    setClientType(CONTACT_TYPES.PROFESSIONNEL)
                  }}
                  className='ant-modal-content__cancel-btn mr-4'>
                  Annuler
                </Button>
                {
                  showModalPaper || step2 ? <Button
                  className='btn-success'
                  onClick={()=>{
                    handleCreateNewContactOrAccount();
                    }}
                  loading={isSubmitCreateNewAccountOrContact}
                >
                  Créer
                </Button> :
                <Button
                  className='btn-success'
                  onClick={()=>{
                    searchCompany();
                    }}
                  loading={isLoading}
                >
                  Vérifier l’existence de la société
                </Button>
                }
                {
                  isUpdateAnything && step2 && (
                    <Button
                      key='nouvelle recherche'
                      onClick={() => searchCompany('Nouvelle recherche')}
                      className='btn-info ml-4'>
                        Nouvelle recherche
                    </Button>
                  )
                }
              </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
export default SearchClient;
