import { PostgrestSingleResponse } from '@supabase/supabase-js';
import supabaseRequest from '../requesters/supabase.request';
const timeSlotService = {
  getTimeSlots: async (query: any): Promise<PostgrestSingleResponse<any[]>> =>
    await supabaseRequest
      .from('time_slots')
      .select()
      .filter('deleted_at', 'is', null)
};
export default timeSlotService;
