import React from "react";
import AppRoutes from "./routes";
import {ConfigProvider} from "antd";

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          "colorPrimary": "#bae34d",
          "colorInfo": "#bae34d"
        }
      }}
    >

      <div className="App">
        <AppRoutes />
      </div>
    </ConfigProvider>
  );
};

export default App;
