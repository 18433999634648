import { useEffect, useState } from 'react';
import { Form, Input, Select, Modal, Button } from 'antd';
import { Sale } from '../models';
import { toast } from 'react-toastify';
import { zohoService } from 'services';

const CreateContact = ({
  isOpenModal,
  setIsOpenModal,
  sales,
  lastModifyById,
  customerId,
  getContact,
}: {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  sales: Sale[],
  lastModifyById: string | null;
  customerId?: string | null;
  getContact: () => Promise<void>;
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
    setIsLoading(false);
  }, [isOpenModal]);

  const handleSubmit = async () => {
    try {
      const vaild = await form.validateFields();
      if (vaild) {
        setIsLoading(true);
        try {
          const values = form.getFieldsValue();
          values.Account_Name = customerId;
          values.Full_Name = ([values.First_Name, values.Last_Name].filter(i => i).join(' '));
          const res: any = await zohoService.createContact(values);
          if (res?.data?.error === true || res.success === false) {
            throw res;
          }
          setIsOpenModal(false);
          setTimeout(() => {
            getContact();
            setTimeout(() => {
              getContact();
            }, 1500);
          }, 500);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          toast.error(
            <div>
              Erreur
              <br />
              {(error as any)?.message ?? ''}
            </div>
          );
        }
      }
    } catch (error) {
      console.error('Validation error:', error);
    }
  }

  return (
    <Modal
      title='AJOUTER UN CONTACT'
      open={isOpenModal}
      onOk={() => setIsOpenModal(false)}
      onCancel={() => setIsOpenModal(false)}
      maskClosable={false}
      centered
      footer={[
        <Button
          key='annuler'
          onClick={() => setIsOpenModal(false)}
          className='ant-modal-content__cancel-btn'>
          Annuler
        </Button>,
        <Button
          key='ajouter'
          type='primary'
          onClick={handleSubmit}
          loading={isLoading}
          className='ant-modal-content__add-btn'>
          Ajouter
        </Button>
      ]}>
        <div className='create-contact-form'>
          <Form form={form} layout="vertical" autoComplete="off">
            <Form.Item name="First_Name" label="Prénom">
              <Input />
            </Form.Item>
            <Form.Item name="Last_Name" label="Nom" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="Email" label="Email" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="Phone" label="Téléphone">
              <Input />
            </Form.Item>
            <Form.Item
              label='Référent'
              name='Owner'
              initialValue={lastModifyById}
              rules={[{ required: true }]}>
              <Select
                placeholder='Sélectionner'
                showSearch
                filterOption={(input, option) =>
                  ((option?.label ?? '') as string)
                    .toLowerCase()
                    .includes((input ?? '').toLowerCase())
                }
                options={sales?.map((option) => ({
                  value: option.crm_user_id,
                  label: option.name
                }))}
              />
            </Form.Item>
          </Form>
        </div>
    </Modal>
  );
};
export default CreateContact;
