import { UploadFile, UploadProps, Image, Upload, Button} from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetProp } from 'antd/es/_util/type';
import { useEffect, useState } from 'react';
import { zohoService } from 'services';
import { toast } from 'react-toastify';
import { TRANSACTION_PROOF } from 'utils/constant';
import {getFileNameWithoutExtension} from "../../utils";

export default function FileUpload({ salesorderId, fileListProp, setFileListProp, disabledButtonDeleteProp, setDisabledButtonDeleteProp } : {salesorderId: string, fileListProp: any, setFileListProp: any, disabledButtonDeleteProp: boolean, setDisabledButtonDeleteProp: any }) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const convertBase64 = (file : any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  interface UploadFileCustom extends UploadFile {
    status_file: string;
    file_name: string;
    dataBase64: string | null;
  }
  const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if(newFileList.length > 0) {
      let itemEnd = newFileList[newFileList.length - 1] as UploadFileCustom;
      if (itemEnd) {
        let indexNameFile = 0;
        if(newFileList.length > 1) {
          let itemGetIndex = newFileList[newFileList.length - 2] as UploadFileCustom;
          try {
            if (itemGetIndex.file_name?.includes(TRANSACTION_PROOF)) {
              itemGetIndex = newFileList[newFileList.length - 3] as UploadFileCustom;
            }
            indexNameFile = parseInt(itemGetIndex.file_name.split("_")[2]) + 1;
          } catch(e) {}
          
          if(!indexNameFile) {
            indexNameFile = 0;
          }
        }
        itemEnd.status = 'done';
        itemEnd.file_name = 'BDC_Client_' + indexNameFile + '.pdf';
        itemEnd.status_file = 'CREATE';
        itemEnd.dataBase64 = itemEnd.originFileObj ? await convertBase64(itemEnd.originFileObj) as string : null;
        // Call API upload file
        const file_upload = [];
        file_upload.push(itemEnd);
        // Check update order
        if(salesorderId && salesorderId !== "") {
          try {
            setIsLoading(true);
            setDisabledButtonDeleteProp(true);
            const { objAttachFileToSalesorder } = await zohoService.uploadFileToSalesorder(salesorderId, file_upload);
            console.log(objAttachFileToSalesorder);
            const newFileListDocument: UploadFileCustom[] = [];
            objAttachFileToSalesorder?.documents?.forEach((item : any) => {
              if (getFileNameWithoutExtension(item.file_name) !== "preuve_de_virement") {
                newFileListDocument.push({
                  ...item,
                  uid : item.document_id,
                  name : item.file_name,
                  status_file : "UPDATE",
                });
              }
            });
            newFileList = newFileListDocument;
            setIsLoading(false);
            setDisabledButtonDeleteProp(false);
          } catch(error) {
            console.log(error);
            setIsLoading(false);
            setDisabledButtonDeleteProp(false);
            toast.error('Erreur');
          }
        }
      }
    }
    let dataListFile : UploadFileCustom[] = [];
    for (let index = 0; index < newFileList.length; index++) {
      const item = newFileList[index] as UploadFileCustom;
      dataListFile.push({
        ...item,
        uid : item.uid,
        name : item.file_name.replace(".pdf", ""),
        status_file : item.status_file == 'UPDATE' ? "UPDATE" : "CREATE",
      });
    };
    setFileListProp(dataListFile);
  };

  useEffect(() => {
    setFileList(fileListProp);
  }, [fileListProp]);

  const uploadButton = (
    <Button loading={isLoading} disabled={isLoading} className='ant-btn-white bnt-upload-file' icon={<UploadOutlined />}></Button>
  );
  return (
    <>
      <Upload
        disabled={disabledButtonDeleteProp}
        customRequest={({ file }) => console.log(file)}
        accept=".pdf"
        listType="picture"
        fileList={fileList}
        className='upload-list-inline'
        onChange={handleChange} >
          {uploadButton}
      </Upload>
    </>
  );
}
