import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TimeSlot } from '../../models';
import { timeSlotService } from '../../services';
import { Loading } from '../../types';

interface TimeSlotState {
  timeSlots: TimeSlot[];
  timeSlotsLoading: Loading;
}

const name = 'timeSlot';
const initialState: TimeSlotState = {
  timeSlots: [],
  timeSlotsLoading: 'idle'
};

export const fetchTimeSlots = createAsyncThunk(
  `${name}/list-management-time-slots`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await timeSlotService.getTimeSlots(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const timeSlotSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeSlots.pending, (state) => {
        state.timeSlotsLoading = 'pending';
      })
      .addCase(fetchTimeSlots.fulfilled, (state, action) => {
        state.timeSlotsLoading = 'idle';
        state.timeSlots = action.payload.data ?? [];
      })
      .addCase(fetchTimeSlots.rejected, (state) => {
        state.timeSlotsLoading = 'idle';
      });
  }
});

export const selectTimeSlots = (state: RootState) => state.timeSlot.timeSlots;
export const selectTimeSlotsLoading = (state: RootState) =>
  state.timeSlot.timeSlotsLoading;

export default timeSlotSlice.reducer;
