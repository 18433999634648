import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Flex, Pagination, Space, Table, Typography  } from "antd";
import { useState } from "react";
const { Text } = Typography;
type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
interface SearchCompanyProps {
  onSelectCompany: (value: any) => void;
  company?: any;
  isLoading?: boolean;
}
interface PaginationSearch {
  page: string;
  pageSize: string;
}
const SearchCompany = (props: SearchCompanyProps) => {
  const { onSelectCompany, company, isLoading } = props;
  const columns = [
    {
      width: "18%",
      title: "Société",
      dataIndex: "company_name",
      render: (_: any, record: any) => {
        return (
          <div style={{ minWidth: "120px" }}>
            <Space direction="vertical" align="baseline">
              <Text>{record.nom_entreprise}</Text>
              <Text>{record.siren}</Text>
            </Space>
          </div>
        );
      },
    },
    {
      width: "30%",
      title: "Adresse",
      dataIndex: "company_address",
      render: (_: any, record: any) => {
        return (
            <div >
            <Text>{record.siege?.adresse_ligne_1}</Text>
          </div>
        );
      },
    },
    {
      width: "20%",
      title: "Domaine d'activité",
      dataIndex: "company_domain",
      render: (_: any, record: any) => {
        return (
            <div >
                <Text>{record.domaine_activite}</Text>
            </div>
        );
      },
    },
    {
      width: "20%",
      title: "Encours dispo",
      dataIndex: "company_forme_juridique",
      render: (_: any, record: any) => {
        return (
            <div >
            <Text>{record.forme_juridique}</Text>
          </div>
        );
      },
    },
    {
      width: "12%",
      title: "Date de création",
      dataIndex: "company_date_created",
      render: (_: any, record: any) => {
        return (
            <div >
              <Text>{record.date_creation}</Text>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Typography.Title
        level={3}
        className="text-center font-italic font-weight-light"
      >
        Proposition Sociétés existantes
      </Typography.Title>
      <SortableContext
        items={company?.list_company?.resultats?.map((item: any) => item.uuid)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          scroll={{ x: "100%" }}
          rowKey={"id"}
          dataSource={company?.list_company?.resultats}
          columns={columns as ColumnTypes}
          onRow={(record, rowIndex) => ({
            onClick: () => onSelectCompany(record),
          })}
          rowClassName="hover-cursor-pointer"
          loading={isLoading}
          style={{width: '88vw'}}
        />
      </SortableContext>
    </>
  );
};

export default SearchCompany;
