import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Form,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
  FormInstance,
  Row,
  Col,
  InputNumber
} from 'antd';
import { 
  CopyOutlined
} from '@ant-design/icons';
import { 
  toast
} from 'react-toastify';
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import {
  EstimateProduct,
  OrderProduct,
  Prestation,
  Product,
  ProductType,
  TimeSlot,
  UnitOption
} from '../../models';
import dayjs, { Dayjs } from 'dayjs';
import {
  CURRENTCIES,
  CUSTOM_PRODUCT_FIELDS,
  PRESTATION,
  PRODUCT_TYPE, STATUSES
} from '../../utils/constant';
import { useDebounce } from '../../hooks';
import { anglicizedFrench } from '../../utils';
import { useAppSelector } from 'store';
import { action } from 'store/slices/action.slices';
const { Text } = Typography;
const { TextArea } = Input;
interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof EstimateProduct;
  record: EstimateProduct;
  calItemPrice: any;
  updateDataSource: any;
  setDataSource: any;
  dataSource: EstimateProduct[];
  form: FormInstance;
  options?: {
    products: Product[];
    prestations: Prestation[];
    timeSlots: TimeSlot[];
    unitOptions: UnitOption[];
    productTypes: ProductType[];
  };
  formType?: 'estimate' | 'order';
  disabled?: boolean,
  enablePrestation?: boolean,
  status?: string,
}

const ProductCell: FC<EditableCellProps> = ({
  title,
  editable,
  children,
  record,
  dataIndex,
  options,
  calItemPrice,
  updateDataSource,
  setDataSource,
  dataSource,
  form,
  formType,
  disabled,
  enablePrestation,
  status,
  ...restProps
} ) =>
{
  const onChangeProductName = (value: string) => {
    form.setFieldsValue({
      line_items: {
        [`${record.uuid}`]: {
          item_custom_fields: {
            [CUSTOM_PRODUCT_FIELDS.NOM_ARTICLE_CLIENT]: value
          }
        }
      }
    });
  };
  const createSeparator = ()=>{
    const separatorContent = '***';
    const separatorLength = 30;
    const separatorName = separatorContent.repeat(separatorLength);
    const numberSeparator = dataSource.filter((i) => i.header_name?.includes( separatorName))?.length;
    return separatorName + '*'.repeat(numberSeparator);
  }
  
  const createNewRow = (uuid: any, index : any, product: any, rowProduct: any, unit: any)=>{
    type ProductType = EstimateProduct | OrderProduct;
    const newRow: ProductType = {
      uuid: uuid,
      item_order: index,
      creationType:'product',
      total: 0,
      quantity: 1,
      discount: 0,
      discount_unit: '%',
      productTypeId: product?.productTypeId,
      item_id: product?.zohoProductId,
      name: product?.proposedName,
      rate: product?.unitPrice,
      description: product?.description,
      item_custom_fields: []
    };
    const { subTotal, total } = calItemPrice(newRow);
    newRow.total = total;
    newRow.subTotal = subTotal;
    rowProduct.push(newRow);
    form.setFieldsValue({
      line_items: {
        [`${uuid}`]: {
          rate: product.unitPrice,
          unit: unit?.name,
          product0: record.product0 || false,
        }
      }
    });
  }
  const onSelectProduct = (zohoProductId: string) => {
    type ProductType = EstimateProduct | OrderProduct;
    const product = options?.products.find(
      (i) => i.zohoProductId === zohoProductId
    ) as Product;
    if(product.multiProducts){
      form.setFieldsValue({ [`line_items.${record.uuid}`]: undefined });
      dataSource = dataSource.filter((i) => i.uuid !== record.uuid);
      const numberMultiProductSameHeaderName = dataSource.filter((i : any) => i.header_name?.includes(product?.proposedName) && i.creationType === 'header' )?.length;
      const rowChildProduct: ProductType[] = [];
      const headerProduct : ProductType = {
        uuid: uuidv4(),
        item_order: record.item_order + 1,
        creationType:'header',
        name: '',
        header_name: product.proposedName + '*'.repeat(numberMultiProductSameHeaderName),
      };
      rowChildProduct.push(headerProduct)
      product.multiProducts?.forEach((item: any, index: any)=>{
        const productChild = options?.products.find(
          (i) => i.id === item
        ) as Product;
        const unit = options?.unitOptions.find((i) => i.id === productChild?.unitId);
        const uuid = uuidv4()
        createNewRow(uuid, record.item_order + index + 1, productChild, rowChildProduct, unit);
      })
      setDataSource([...dataSource, ...rowChildProduct]);
    }
    else{
      const unit = options?.unitOptions.find((i) => i.id === product?.unitId);
      const rowChildProduct: ProductType[] = [];
      const lastIndex = dataSource?.length > 0 ? dataSource?.length - 2 : 0;
      let lastElement : ProductType = dataSource[lastIndex];
      const findProduct = options?.products.find(
        (i) => i.zohoProductId === lastElement?.item_id
      ) as Product;
      const isChildProduct = options?.products.find(
        (i) => i.multiProducts?.includes(findProduct?.id)
      ) as Product;
      if(isChildProduct){
        // Add separator character with custom content and length
        form.setFieldsValue({ [`line_items.${record.uuid}`]: undefined });
        dataSource = dataSource.filter((i) => i.uuid !== record.uuid);
        const separatorProduct : ProductType = {
          uuid: record.uuid,
          item_order: record.item_order,
          creationType:'header',
          name: '',
          header_name: createSeparator(),
        };
        rowChildProduct.push(separatorProduct)
        createNewRow(uuidv4(), record.item_order + 1, product, rowChildProduct, unit);
        setDataSource([...dataSource, ...rowChildProduct]);
      }
      else{
        updateDataSource(
          {
            productTypeId: product?.productTypeId,
            item_id: product?.zohoProductId,
            name: product?.proposedName,
            rate: product?.unitPrice,
            description: product?.description
          },
          record.uuid
        );
        form.setFieldsValue({
          line_items: {
            [`${record.uuid}`]: {
              rate: product.unitPrice,
              unit: unit?.name,
              product0: record.product0 || false,
            }
          }
        });
      }
    }
    
  };
  const handleCopyData = (dataCopy:any | undefined) => {
    dataCopy = dataCopy?.toString();
    if(dataCopy !== undefined && dataCopy.length > 0) {
      console.log("dataCopy: " + dataCopy);
      navigator.clipboard.writeText(dataCopy? dataCopy : "");
      toast.success(
        <div>
          Copié dans le presse-papier
        </div>
      );
    }
  };
  const handleCopyDataFollowFormName = (FieldNameForm:any) => {
    handleCopyData(form.getFieldValue(FieldNameForm));
  };
  const handleCopyDataDate = (FieldNameForm:any) => {
    const dateString = new Date(form.getFieldValue(FieldNameForm)).toLocaleDateString("en-GB");
    console.log(dateString);
    handleCopyData(dateString);
  };
  const handleCopyDataPrice = () => {
    const dataQuantity = form.getFieldValue(['line_items', `${record.uuid}`, 'quantity']);
    const totalItem = record?.total as number / dataQuantity;
    console.log(record.total);
    console.log("dataQuantity " + dataQuantity);
    console.log("totalItem: " + totalItem);
    handleCopyData(totalItem);
  };
  const formatValue = (value :any) =>{
    return value
      ?.toString()
      .replace(/\s/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      .replace('.', ',');
  }

  const parseValue = (value :any) =>{
    return value?.replace(/\s/g, '').replace(/,/g, '.');
  }
  const handleOnlyEnterNumber = (value :any) =>{
    const re = /^[0-9\b.]+$/;
    if (!re.test(value.key) && value.key !== 'Backspace') {
      value.preventDefault();
    }
  }
  const handleUpdateDataSource = useDebounce((data: any, uuid: any) => {
    updateDataSource(data, uuid);
  }, 250);
  const actionSubmit = useAppSelector(action);
  let childrenNode: any = <></>;
  switch (dataIndex) {
    case 'name':
      childrenNode = generateProductNameCell({
        record,
        options,
        onSelectProduct,
        onChangeProductName,
        handleCopyData,
        handleCopyDataFollowFormName
      });
      break;
    case 'item_custom_fields':
      childrenNode = generatePrestationCell({ record, options, formType, status,
        handleCopyData,
        handleCopyDataDate,
        handleUpdateDataSource,
        formatValue,
        parseValue,
        handleOnlyEnterNumber
      });
      break;
    case 'rate':
      childrenNode = generatRateCell({
        record,
        options,
        formType,
        status,
        handleUpdateDataSource,
        handleCopyDataFollowFormName,
        handleCopyDataPrice,
        actionSubmit
      });
      break;
    case 'quantity':
      childrenNode = generatQuantityCell({
        record,
        handleUpdateDataSource
      });
      break;
    case 'discount':
      childrenNode = generatDiscountCell({
        record,
        handleUpdateDataSource
      });
      break;
    default:
      childrenNode = null;
  }

  let childNode = children;

  if (editable) {
    childNode = childrenNode;
  }

  return <td {...restProps}>{childNode}</td>;
};

const generateProductNameCell = ({
  record,
  options,
  ...props
}: {
  record: EstimateProduct;
  options?: { products: Product[] };
  onSelectProduct?: (value: string) => void;
  onChangeProductName: (value: string) => void;
  handleCopyData: (value: string) => void;
  handleCopyDataFollowFormName: (FieldNameForm:any) => void;
}) => {
  let content: JSX.Element | null = null;

  // if normal product
  const product = options?.products.find((product) => product.zohoProductId === record.item_id)
  if (record.item_id) {
    const customName =
      record?.item_custom_fields?.find(
        (item) => item.label === CUSTOM_PRODUCT_FIELDS.NOM_ARTICLE_CLIENT
      )?.value ?? product?.titreClient;
    content = (
      <>
        <Row gutter={[16, 0]}>
          <Col
            xs={{ span: 22 }}>
              Référence interne
          </Col>
          <Col
          className='prodct-cell__display-right'
            xs={{ span: 2 }}>
            <CopyOutlined
                  onClick={() => props.handleCopyData(record.name as string)}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
          </Col>
        </Row>
        <Form.Item
              className='ant-form-item-vertical mb-2'
              name={['line_items', `${record.uuid}`, 'name']}
              initialValue={record.name}>
              <Text >
                <Text underline strong>{record.name}</Text>
                {product?.sku && ` - ${product.sku}`}
              </Text>
            </Form.Item>
        <Form.Item
          label='Nom produit client'
          name={[
            'line_items',
            `${record.uuid}`,
            'item_custom_fields',
            CUSTOM_PRODUCT_FIELDS.NOM_ARTICLE_CLIENT
          ]}
          initialValue={customName}
          rules={[{ required: true, message: 'Ce champ est requis' }]}
          className='ant-form-item-vertical'>
          <Input placeholder='Référence interne'/>
        </Form.Item>
        <Form.Item
          name={['line_items', `${record.uuid}`, 'description']}
          initialValue={record.description}>
          <TextArea
              autoSize={{ minRows: 1, maxRows: 22 }}
              className='logistique__document-textarea textareaProduct'
              value= {record.description}
          />
        </Form.Item>
        <Form.Item
          className='hidden'
          name={['line_items', `${record.uuid}`, 'item_id']}
          initialValue={record.item_id}>
          <Input placeholder='Référence interne' />
        </Form.Item>
      </>
    );
  } else if (record.creationType === 'header') {
    // header
    content = (
      <Form.Item
        name={['line_items', `${record.uuid}`, 'header_name']}
        initialValue={record.header_name}
        rules={[{ required: true }]}>
        <Input placeholder='Titre' />
      </Form.Item>
    );
  } else {
    // add new custom product
    const customName =
      record?.item_custom_fields?.find(
        (item) => item.label === CUSTOM_PRODUCT_FIELDS.NOM_ARTICLE_CLIENT
      )?.value ?? product?.titreClient;
    content = (
      <>
        <Row gutter={[16, 0]}
          style={{ marginBottom:10 }}>
          <Col
            xs={{ span: 22 }}>
              <span style={{ color: '#ff4d4f', fontFamily: "SimSun,sans-serif" }}>* </span>Référent interne
          </Col>
          <Col
          className='prodct-cell__display-right'
            xs={{ span: 2 }}>
            <CopyOutlined
                  onClick={() => props.handleCopyDataFollowFormName(['line_items', `${record.uuid}`, 'name'])}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
          </Col>
        </Row>
        <Form.Item
          className='ant-form-item-vertical mb-2'
          name={['line_items', `${record.uuid}`, 'name']}
          initialValue={record.name}
          rules={[{ required: true }]}>
          <Input
            placeholder='Ajouter un produit'
            onChange={(e) => props.onChangeProductName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label='Nom produit client'
          name={[
            'line_items',
            `${record.uuid}`,
            'item_custom_fields',
            CUSTOM_PRODUCT_FIELDS.NOM_ARTICLE_CLIENT
          ]}
          initialValue={customName}
          rules={[{ required: true, message: 'Ce champ est requis' }]}
          className='ant-form-item-vertical'>
          <Input placeholder='Référence interne' />
        </Form.Item>
        <Form.Item
          name={['line_items', `${record.uuid}`, 'description']}
          initialValue={record.description}>
          <TextArea
            className='logistique__document-textarea textareaProduct'
            value={record.description}
            placeholder='Ajouter une description à votre article'
            style={ { minHeight: '120px' } }
            autoSize={true}
          />
        </Form.Item>
      </>
    );
  }

  // new product
  if (record.creationType === 'product' && !record.item_id) {
    content = (
      <Form.Item
        rules={[{ required: true }]}
        name={['line_items', `${record.uuid}`, 'item_id']}>
        <Select
          placeholder='Sélectionner'
          optionFilterProp='children'
          onChange={props.onSelectProduct}
          showSearch
          filterOption={(input, option) =>
              anglicizedFrench((option?.label ?? '') as string)
              .toLowerCase()
              .includes(anglicizedFrench(input ?? '').toLowerCase())
          }
          options={options?.products.map((product) => ({
            value: product.zohoProductId,
            label: product.proposedName + ' - ' + product.sku
          }))}
        />
      </Form.Item>
    );
  }

  return record.creationType !== 'header' ? (
    <div style={{ minWidth: '350px', maxWidth: '650px' }}>
      {content}
      <Form.Item
        name={['line_items', `${record.uuid}`, 'item_order']}
        initialValue={record.item_order}
        className='hidden'
        rules={[{ required: true }]}>
        <Input placeholder='Titre' />
      </Form.Item>
    </div>
  ) : (
    <>
      {content}
      <Form.Item
        name={['line_items', `${record.uuid}`, 'item_order']}
        initialValue={record.item_order}
        className='hidden'
        rules={[{ required: true }]}>
        <Input placeholder='Titre' />
      </Form.Item>
    </>
  );
};

const generatePrestationCell = ({
  record,
  options,
  formType,
  handleUpdateDataSource,
  formatValue,
  parseValue,
  handleOnlyEnterNumber,
  enablePrestation,
  status,
  ...props
}: {
  record: EstimateProduct;
  options?: {
    prestations: Prestation[];
    timeSlots: TimeSlot[];
    productTypes: ProductType[];
  };   
  formType?: 'estimate' | 'order';
  handleUpdateDataSource : any;
  formatValue : any;
  parseValue : any;
  handleOnlyEnterNumber : any;
  handleCopyData: (value: string) => void;
  handleCopyDataDate: (FieldNameForm:any) => void;
  enablePrestation?: Prestation[];
  status?: string;
  }) =>
{
  const productType = options?.productTypes.find(
    (productType) => productType.id === record.productTypeId
  );
  if (
    ((record.creationType === 'product' && record.productTypeId) ||
      (record.line_item_id && record.item_custom_fields?.length)) &&
    record.item_id &&
    (productType?.key === PRODUCT_TYPE.BENNES ||
      productType?.key === PRODUCT_TYPE.CAMIONS ||
      productType?.key === PRODUCT_TYPE.SEMI ||
      productType?.key === PRODUCT_TYPE.BIGBAGS)
  ) {
    const prestations = options?.prestations.filter(
      (prestation) => prestation.productTypeId === record.productTypeId
    );
    const timeSlots = options?.timeSlots.filter(
      (timeSlot) => timeSlot.productTypeId === record.productTypeId
    );
    const defaultPrestation =
      record.productTypeId ===
      options?.productTypes.find(
        (productType) => productType.key === PRODUCT_TYPE.BENNES
      )?.id
        ? prestations?.find((prestation) => prestation.key === PRESTATION.POSE)
        : prestations?.find(
            (prestation) => prestation.key === PRESTATION.COLLECTE
          );
    const prestation =
      prestations?.find(
        (i) =>
          i.key ===
          record.item_custom_fields?.find(
            (item) => item.label === CUSTOM_PRODUCT_FIELDS.PRESTATION_TYPE
          )?.value
      ) ?? defaultPrestation;
    let timeSlot: TimeSlot | undefined =
      timeSlots?.find(
        (i) =>
          i.name ===
          record.item_custom_fields?.find(
            (item) => item.label === CUSTOM_PRODUCT_FIELDS.TIME_SLOT
          )?.value
      ) ?? timeSlots?.[0];
    let prestationDate: Dayjs | undefined = dayjs(
      record.item_custom_fields?.find(
        (item) => item.label === CUSTOM_PRODUCT_FIELDS.PRESTATION_DATE
      )?.value ?? dayjs().add(1, 'day').format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    );
    if (formType === 'estimate') {
      timeSlot =
        timeSlots?.find(
          (i) =>
            i.name ===
            record.item_custom_fields?.find(
              (item) => item.label === CUSTOM_PRODUCT_FIELDS.TIME_SLOT
            )?.value
        ) ?? undefined;
      prestationDate = record.item_custom_fields?.find(
        (item) => item.label === CUSTOM_PRODUCT_FIELDS.PRESTATION_DATE
      )?.value
        ? dayjs(
            record.item_custom_fields?.find(
              (item) => item.label === CUSTOM_PRODUCT_FIELDS.PRESTATION_DATE
            )?.value,
            'YYYY-MM-DD'
          )
        : undefined;
    }
    return (
      <div>
        <Space direction='vertical'>
          {prestations?.length ? (
            <Form.Item
              className='ant-form-item-vertical mb-0'
              label='Type de prestation'
              rules={[{ required: true }]}
              initialValue={prestation?.key}
              name={[
                'line_items',
                `${record.uuid}`,
                'item_custom_fields',
                'Type de prestation'
              ]}>
              <Select
                placeholder='Sélectionner'
                disabled={status !== undefined ? ((status !== STATUSES.sent.key) && (status !== STATUSES.draft.key)) : undefined}
                options={prestations?.map((prestation) => ({
                  value: prestation.key,
                  label: prestation.name
                }))}
              />
            </Form.Item>
          ) : null}
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 20 }}>
              {formType !== 'estimate' ? (<span style={{ color: '#ff4d4f', fontFamily: "SimSun,sans-serif" }}>* </span>) : ''}Date de prestation
            </Col>
            <Col
              xs={{ span: 4 }}>
                <CopyOutlined
                  onClick={() => props.handleCopyDataDate([
                    'line_items',
                    `${record.uuid}`,
                    'item_custom_fields',
                    CUSTOM_PRODUCT_FIELDS.PRESTATION_DATE
                  ])}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
            </Col>
          </Row>
          <Form.Item
                className='ant-form-item-vertical mb-0'
                rules={[{ required: formType === 'estimate' ? false : true }]}
                initialValue={
                  record.creationType === 'product' && formType === 'estimate'
                    ? null
                    : prestationDate
                }
                name={[
                  'line_items',
                  `${record.uuid}`,
                  'item_custom_fields',
                  CUSTOM_PRODUCT_FIELDS.PRESTATION_DATE
                ]}>
                <DatePicker
                    style={{ width: '100%' }}
                    locale={locale}
                    disabled={status !== undefined ? ((status !== STATUSES.sent.key) && (status !== STATUSES.draft.key)) : undefined}
                />
              </Form.Item>
          <Form.Item
            className='ant-form-item-vertical mb-0'
            label='Créneau horaire'
            name={[
              'line_items',
              `${record.uuid}`,
              'item_custom_fields',
              CUSTOM_PRODUCT_FIELDS.TIME_SLOT
            ]}
            initialValue={
              record.creationType === 'product' && formType === 'estimate'
                ? null
                : timeSlot?.name
            }
            rules={[{ required: formType === 'estimate' ? false : true }]}>
            <Select
              placeholder='Sélectionner'
              disabled={status !== undefined ? ((status !== STATUSES.sent.key) && (status !== STATUSES.draft.key)) : undefined}
              options={timeSlots?.map((timeSlot) => ({
                value: timeSlot.name,
                label: timeSlot.name
              }))}
            />
          </Form.Item>
          {
            productType?.key === PRODUCT_TYPE.BENNES && (<>
              <Form.Item
                name={[
                  'line_items',
                  `${record.uuid}`,
                  'item_custom_fields',
                  CUSTOM_PRODUCT_FIELDS.FORFAIT_MAX
                ]}
                className='ant-form-item-vertical mb-0'
                label='Forfait max (tonne)'
                initialValue={record.forfait_max}>
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={status !== undefined ? ((status !== STATUSES.sent.key) && (status !== STATUSES.draft.key)) : undefined}
                  className='input-forfait-max'
                  placeholder='Forfait max (tonne)'
                  formatter={formatValue}
                  parser={parseValue}
                  onKeyDown={handleOnlyEnterNumber}
                 />
              </Form.Item>
              <Form.Item
                name={[
                  'line_items',
                  `${record.uuid}`,
                  'item_custom_fields',
                  CUSTOM_PRODUCT_FIELDS.PRIX_TONNE_SUP
                ]}
                className='ant-form-item-vertical mb-0'
                label='Prix tonne sup'
                initialValue={record.prix_tonne_sub}>
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={status !== undefined ? ((status !== STATUSES.sent.key) && (status !== STATUSES.draft.key)) : undefined}
                    className='input-prix-tonne'
                    placeholder='Prix tonne sup'
                    formatter={formatValue}
                    parser={parseValue}
                    onKeyDown={handleOnlyEnterNumber}
                  />
              </Form.Item>
            </>)
          }
          
        </Space>
      </div>
    );
  }
};
const generatRateCell = ({
  record,
  handleUpdateDataSource,
  options,
  formType,
  status,
  actionSubmit,
  ...props
}: {
  record: EstimateProduct;
  handleUpdateDataSource: any;
  options?: {
    prestations: Prestation[];
    timeSlots: TimeSlot[];
    unitOptions: UnitOption[];
  };
  formType?: 'estimate' | 'order';
  status?: string;
  actionSubmit?: any;
  handleCopyDataFollowFormName: (FieldNameForm:any) => void;
  handleCopyDataPrice: () => void;
}) => {
  const unit =
    record?.item_custom_fields?.find((item) => item.label === 'Unite')?.value ||
    options?.unitOptions[0].name;
  if (record.creationType !== 'header') {
    return (
      <div className='text-center'>
        <Space
          direction='vertical'
          align='baseline'
          className='space-prix-unitaire'>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                name={['line_items', `${record.uuid}`, 'rate']}
                style={{ marginBottom: '0' }}
                initialValue={record.rate}
                rules={[
                  { required: (actionSubmit==='validate-and-create-order' || actionSubmit==='sent') ? true : false},
                  () => ({
                    validator(_: any, value: any) {
                      if( formType === 'estimate' && (status === STATUSES.draft.key || !status) && actionSubmit!=='validate-and-create-order' && actionSubmit!=='sent'){
                        if (!record.product0 && (isNaN(value) || parseFloat(value) < 0)) {
                          return Promise.reject();
                        }
                      }else{
                        if (!record.product0 && (!value || isNaN(value) || parseFloat(value) <= 0)) {
                          return Promise.reject();
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}>
                <InputNumber
                  style={{ width: '105px' }}
                  suffix='€'
                  onChange={(value) =>
                    handleUpdateDataSource({ rate: value }, record.uuid)
                  }
                  className='input-prix-unitaire'
                  formatter={
                    (value) =>
                      value!.toString()
                        .replace(/\s/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                        .replace('.', ',')
                  }
                  parser={
                    (value) =>
                      value!
                        .replace(/\s/g, '')
                        .replace(/,/g, '.')
                  }
                />
              </Form.Item>
            </Col>
            <Col
              style={{ marginTop:5 }}
              xs={{ span: 6 }}>
                <CopyOutlined
                  onClick={() => props.handleCopyDataPrice()}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
            </Col>
          </Row>
          <div className='input-line-under'></div>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
                <Form.Item
                  name={['line_items', `${record.uuid}`, 'unit']}
                  className='ant-form-item-vertical mb-2'
                  rules={[{ required: true }]}
                  initialValue={unit}>
                  <Select
                    className='text-left'
                    style={{ width: '105px' }}
                    options={options?.unitOptions.map((unit) => ({
                      value: unit.name,
                      label: unit.name
                    }))}
                  />
                </Form.Item>
            </Col>
            <Col
              style={{ marginTop:5 }}
              xs={{ span: 6 }}>
                <CopyOutlined
                  onClick={() => props.handleCopyDataFollowFormName(['line_items', `${record.uuid}`, 'unit'])}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
            </Col>
          </Row>
        </Space>
      </div>
    );
  }
};

const generatQuantityCell = ({
  record,
  handleUpdateDataSource,
}: {
  record: EstimateProduct;
  handleUpdateDataSource: any;
}) => {
  if (record.creationType !== 'header') {
    return (
      <div>
        <Space>
          <Form.Item
            name={['line_items', `${record.uuid}`, 'quantity']}
            rules={[
              { required: true },
              () => ({
                validator(_: any, value: any) {
                  if (isNaN(value) || parseFloat(value) < 0) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                }
              })
            ]}
            initialValue={record.quantity}>
            <Input
              style={{ width: '80px' }}
              onChange={(e) =>
                handleUpdateDataSource(
                  { quantity: e.target.value },
                  record.uuid
                )
              }
              className='text-right'
            />
          </Form.Item>
        </Space>
      </div>
    );
  }
};

const generatDiscountCell = ({
  record,
  handleUpdateDataSource,
}: {
  record: EstimateProduct;
  handleUpdateDataSource: any;
}) => {
  if (record.creationType !== 'header') {
    return (
      <div className='text-center'>
        <Space direction='horizontal' align='baseline' className='space-remise'>
          <div className='remise-hover-input-and-select'>
            <Form.Item
              name={['line_items', `${record.uuid}`, 'discount']}
              initialValue={record.discount || 0}
              rules={[
                { required: true },
                () => ({
                  validator(_: any, value: any) {
                    if (value === undefined || value === null || value === '') {
                      return Promise.reject();
                    }
                    if (isNaN(value)) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  }
                })
              ]}>
              <Input
                onChange={(e) =>
                  handleUpdateDataSource(
                    { discount: e.target.value },
                    record.uuid
                  )
                }
                className='text-right'
              />
            </Form.Item>
            <Form.Item
              name={['line_items', `${record.uuid}`, 'discount_unit']}
              initialValue={record.discount_unit || CURRENTCIES[0].value}
            >
              <Select
                className='text-right'
                listItemHeight={32}
                onChange={(value) =>
                  handleUpdateDataSource({ discount_unit: value }, record.uuid)
                }
                options={CURRENTCIES}
              />
            </Form.Item>
          </div>
        </Space>
      </div>
    );
  }
};

export default ProductCell;