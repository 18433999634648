import { Estimate, Order } from 'models';
import { Address } from '../types';
import { CUSTOM_ORDER_FIELDS, CUSTOM_QUOTATION_FIELDS } from './constant';
import { zohoService } from 'services';
import { toast } from 'react-toastify';
export const convertAddress = (place: any): null | Address => {
  if (
    !place?.address_components ||
    !place?.formatted_address ||
    !place?.place_id
  ) {
    return null;
  }
  const result: {
    city?: string;
    zip?: string;
    country?: string;
    address?: string;
    latitude?: string;
    longitude?: string;
    fullAddress?: string;
    isCompleted?: boolean;
  } = {};
  const { address_components, formatted_address } = place;

  for (const component of address_components) {
    if (component.types.includes('postal_town')) {
      result.city = component.long_name;
    } else if (component.types.includes('locality')) {
      result.city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1') && !result.city) {
      result.city = component.long_name;
    } else if (component.types.includes('postal_code')) {
      result.zip = component.long_name;
    } else if (component.types.includes('country')) {
      result.country = component.long_name;
    } else if (component.types.includes('route')) {
      result.address = (result.address ? result.address + ' ' : '') + component.long_name;
    } else if (component.types.includes('street_number')) {
      result.address = component.long_name + (result.address ? ' ' + result.address : '');
    }
  }

  const { lat, lng } = place.geometry.location;

  result.latitude = lat ? typeof lat === 'number' ? lat : lat() : '';
  result.longitude = lng ? typeof lng === 'number' ? lng : lng() : '';
  result.isCompleted = (result.city && result.zip && result.address) ? true : false;
  // result.fullAddress = formatted_address;
  result.fullAddress = getFullAddress(result);
  return result;
};

export const parseAddress = (address: string) => {
  var result: {
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    shortAddress?: string;
  } = {};
  if (address) {
    result.address = address;
    var parts = address.split(', ');
    if (parts.length === 3) {
      result.shortAddress = parts[0];
      const zip_city = parts[1].split(' ');
      result.zip = !isNaN(zip_city[0] as any) ? zip_city.shift() : '';
      result.city = zip_city.join(' ');
      result.country = parts[2];
    } else if (parts.length === 2) {
      result.shortAddress = '';
      var zip_city = parts[0].split(' ');
      result.zip = !isNaN(zip_city[0] as any) ? zip_city.shift() : '';
      result.city = zip_city.join(' ');
      result.country = parts[1];
    } else {
      result.zip = '';
      result.city = '';
      result.country = '';
    }
  }
  return result;
};

export const getFullAddress = (addressObj: Address) => {
  let result = '';
  if (addressObj) {
    result = [
      addressObj.address,
      ((addressObj?.zip ?? '') + ' ' + (addressObj?.city ?? '')).trim(),
    ].filter((i) => i).join(', ');
  }
  return result;
};

export const getEstimateAddressChantier = (estimate: Estimate) => {
  const chantierAddresss: Address = {
    address: estimate?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER
    )?.value,
    zip: estimate?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_ZIP
    )?.value,
    city: estimate?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_CITY
    )?.value,
    latitude: estimate?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_LAT
    )?.value ?? '',
    longitude: estimate?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_LONG
    )?.value ?? '',
  };
  chantierAddresss.fullAddress = getFullAddress(chantierAddresss);
  return chantierAddresss;
}

export const getOrderAddressChantier = (order: Order) => {
  const chantierAddresss: Address = {
    address: order?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_ORDER_FIELDS.ADDRESS_CHANTIER
    )?.value,
    zip: order?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_ORDER_FIELDS.ADDRESS_CHANTIER_ZIP
    )?.value,
    city: order?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_ORDER_FIELDS.ADDRESS_CHANTIER_CITY
    )?.value,
    latitude: order?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_LAT
    )?.value ?? '',
    longitude: order?.custom_fields?.find(
      (item) =>
        item.api_name === CUSTOM_QUOTATION_FIELDS.ADDRESS_CHANTIER_LONG
    )?.value ?? '',
  };
  chantierAddresss.fullAddress = getFullAddress(chantierAddresss);
  return chantierAddresss;
}

export const getFullName = (firstName?: string | null, lastName?: string | null) => {
  return [firstName, lastName].filter(i => i).join(' ');
}

export const pick = <T extends object, K extends keyof T>(
  whitelisted: K[] | string[],
  target: T,
  defaultValue?: any
) =>
  Object.fromEntries(
    whitelisted.map((key) => [
      key,
      key in target ? target[key as K] : defaultValue,
    ])
  );

export const numberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const frenchCurrencyFormat = (amount: number | string) => {
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace('.', ',');
};


export const getExistProp = (data: any) => {
  const result = Object.keys(data).reduce((nextData, key) => {
    if (data[key]) {
      return {
        ...nextData,
        [key]: data[key],
      };
    }

    return nextData;
  }, {});

  return result;
};

export const getValueByCustomFieldId = (
  obj: any & { custom_fields: any },
  customFieldId: string
) => {
  return obj?.custom_fields?.[customFieldId];
};

export const isObjectEmpty = (objectName: any) => {
  return !objectName || Object.keys(objectName).length === 0;
}

export const calProductPrice = (item: any) => {
  let subTotal = 0;
  if(!item.product0){
    subTotal = Number(
      (item.rate && item.quantity
        ? (item.rate as number) * (item.quantity as number)
        : 0
      ).toFixed(2)
    );
  }
  let discount = (item.discount as number) || 0;
  if (item.discount_unit === '%') {
    discount = Number(((subTotal * discount ?? 0) / 100).toFixed(2));
  }
  const total = Number((subTotal - discount).toFixed(2));
  return { subTotal, discount, total };
};


export const setValueForEmailLibres = (data: any)=>{
  const MAX_EMAILS = 5;
  const emailArray = data[CUSTOM_QUOTATION_FIELDS.EMAILCC];

  for (let index = 0; index < MAX_EMAILS; index++) {
    const email = emailArray?.[index] || null;
    const name = `EMAILCC0${index + 1}` as keyof typeof CUSTOM_QUOTATION_FIELDS;

    data.custom_fields.push({
      customfield_id: CUSTOM_QUOTATION_FIELDS[name],
      value: email,
    });
  }

  data.custom_fields.push({
    customfield_id: CUSTOM_QUOTATION_FIELDS.EMAILCC,
    value: data[CUSTOM_QUOTATION_FIELDS.EMAILCC].join(','),
  });
};

export const anglicizedFrench = (value: string) => {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};

export const getLastModifyByIdFromLastModifyByIdBooks = (sales: any, lastModifyByIdBooks: string) => {
  return sales?.find(
    (i: any) => i.books_user_id === lastModifyByIdBooks
  )?.crm_user_id;
};

export const getContactCRM = async (crmContactId: string, setContact: any, setZcrmContactId?: any | null) => {
  try {
    const { data } = await zohoService.getContactCRM(crmContactId);
    if(data?.contact){
      setContact(data?.contact);
      if(setZcrmContactId){
        setZcrmContactId(data?.contact?.zcrm_contact_id);
      }
    }
    else{
      toast.error("Impossible d'obtenir les nouvelles données client. Veuillez réessayer en recherchant le client");
    }
  } catch(error) {
    toast.error('Erreur');
  }
};

export const getFileNameWithoutExtension = (fileName: string) => {
  return fileName.replace(/\.[^/.]+$/, "");
};
export const getRappelDevis = (data: any) => {
  const { relance_devis_automatique, tache_de_rappel } = data;
  return relance_devis_automatique
    ? (tache_de_rappel ? 'Relance devis + Rappel' : 'Relance devis')
    : (tache_de_rappel ? 'Rappel' : '');
};