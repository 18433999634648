import { Typography, Tag } from "antd";
import { ORDER_STATUSES, STATUSES } from "../../utils/constant";
const { Title } = Typography;

const MainTitle = ({
  type = 'estimate',
  parent,
  child,
  otherStyles,
  status,
}: {
  type?: 'estimate' | 'order',
  parent: string;
  child?: string;
  otherStyles?: Object;
  status?: string;
}) => {
  const statusFormat =
    type === 'order'
      ? ORDER_STATUSES[status as keyof typeof ORDER_STATUSES]
      : STATUSES[status as keyof typeof STATUSES];
  return (
    <>
      <Title
        level={3}
        className="main-title mb-0"
        style={{ display: "flex", ...otherStyles }}
      >
        <span>{parent}</span> / <strong className="main-title__child">{child}</strong>
        {statusFormat && <Tag className={`ml-5 ${statusFormat?.color}`}>{statusFormat?.title}</Tag>}
      </Title>
    </>
  );
};
export default MainTitle;
