import { useEffect, useState } from "react";
import {Modal, Button, Upload, Typography, UploadFile} from "antd";
import {
  DeleteOutlined,
  FilePdfOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { TRANSACTION_PROOF } from "utils/constant";

const { Dragger } = Upload;

const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const TransactionProof = ({
  isOpenModal,
  setIsOpenModal,
  onSubmit,
}: {
  isOpenModal: boolean;
  salesorderId?: string;
  file: any;
  onSubmit: React.Dispatch<any>;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleDownloadProof: React.Dispatch<any>
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    setSelectedFile(null);
  }, [isOpenModal]);

  const handleSubmit = async () => {
    if (selectedFile) {
      onSubmit(selectedFile);
      setIsOpenModal(false);
    }
  };


  return (
    <div>
        {isOpenModal && (<Modal
        title=""
        open={isOpenModal}
        onOk={() => setIsOpenModal(false)}
        onCancel={() => setIsOpenModal(false)}
        maskClosable={false}
        centered
        footer={[
          <Button
            key="annuler"
            onClick={() => setIsOpenModal(false)}
            className="ant-modal-content__cancel-btn"
          >
            Annuler
          </Button>,
          <Button
            key="ajouter"
            type="primary"
            loading={isLoading}
            onClick={handleSubmit}
            className="ant-modal-content__add-btn"
          >
            Télécharger
          </Button>,
        ]}
      >
        <div style={{ marginTop: "70px" }}>
          <Dragger
            name="file"
            multiple={false}
            disabled={false}
            beforeUpload={() => {
              return false;
            }}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            showUploadList={false}
            onChange={async (info) => {
              console.log(info);
              if (info?.fileList?.length > 0) {
                const fileItem = info?.fileList[info?.fileList?.length - 1];
                const fileType = fileItem.originFileObj?.name.split(".").pop();
                const file = {
                  ...fileItem,
                  name: TRANSACTION_PROOF + "." + fileType,
                  file_name: TRANSACTION_PROOF + "." + fileType,
                  transaction_proof: true,
                  fileType,
                  status_file: "CREATE",
                  dataBase64: fileItem.originFileObj
                    ? ((await convertBase64(fileItem.originFileObj)) as string)
                    : null,
                };
                setSelectedFile(file);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "#A6C84D" }} />
            </p>
            <p className="ant-upload-text">
              Virement, besoin de la preuve de virement
            </p>
            <p className="ant-upload-hint">
              choisissez un fichier ou faites-le glisser et déposez-le ici
            </p>
          </Dragger>
          {selectedFile && (
            <div style={{ paddingTop: 10 }}>
              <FilePdfOutlined style={{ color: "#95C515" }} />
              <span className="ml-1 mr-2" style={{ color: "#95C515" }}>
                {selectedFile?.name}
              </span>
              <DeleteOutlined
                onClick={() => setSelectedFile(null)}
                style={{ cursor: "pointer", color: "red" }}
              />
            </div>
          )}
        </div>
      </Modal>
      )}
    </div>
  );
};
export default TransactionProof;
