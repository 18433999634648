import { Layout, Card, Image } from "antd";
import Logo from "../../assets/images/main-logo-ecodrop.png";
import moment from "moment";
const { Sider } = Layout;

const Sidebar = () => {
  const currentYear = moment().format("YYYY");
  return (
    <Sider width={100} theme="dark" className="main-sidebar">
      <Card bordered={false} className="main-sidebar__main-logo">
        <Image
          width={80}
          // height={40}
          preview={false}
          src={Logo}
          className="main-sidebar__logo-image"
        />
      </Card>
      <div className="bottom-sidebar">
        <p className="copyright-text text-center">Copyright &copy; Ecodrop <br/>2023 - {currentYear}</p>
        <p className="copyright-text"> {process.env.REACT_APP_VERSION ?? '1.0.0'}</p>
      </div>
    </Sider>
  );
};
export default Sidebar;
