import { PostgrestSingleResponse } from '@supabase/supabase-js';
import supabaseRequest from '../requesters/supabase.request';
const productTypeService = {
  getProductTypes: async (
    query: any
  ): Promise<PostgrestSingleResponse<any[]>> =>
    await supabaseRequest
      .from('product_types')
      .select()
      .filter('deleted_at', 'is', null)
};
export default productTypeService;
