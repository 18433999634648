import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import accountSlice from './slices/account.slices';
import saleSlice from './slices/sale.slices';
import productSlice from './slices/product.slices';
import prestationSlice from './slices/prestation.slices';
import timeSlotSlice from './slices/time_slot.slices';
import zohoSlices from './slices/zoho.slices';
import productTypeSlice from './slices/product_type.slices';
import actionSlices from './slices/action.slices';

const store = configureStore({
  reducer: {
    account: accountSlice,
    sale: saleSlice,
    product: productSlice,
    prestation: prestationSlice,
    timeSlot: timeSlotSlice,
    zoho: zohoSlices,
    productType: productTypeSlice,
    action: actionSlices,
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
