import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Contact, Estimate, Order } from '../../models';
import { zohoService } from '../../services';

interface ZohoState {
  contact: Contact | null;
  estimate: Estimate | null;
  salesorder: Order | null;
  paymentClient: string | null;
}

const name = 'zoho';
const initialState: ZohoState = {
  contact: null,
  estimate: null,
  salesorder: null,
  paymentClient: null,
};

export const findEstimateById = createAsyncThunk(
  `${name}/find-estimate-by-id`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response: {dataEstimate: any, dataContact: any} = {
        dataEstimate: null,
        dataContact: null
      };
      const dataEstimate = await zohoService.getEstimate(payload);
      response.dataEstimate = dataEstimate;
      if (dataEstimate?.data?.error) {
        return rejectWithValue(dataEstimate.data);
      }
      else if(dataEstimate?.data?.estimate?.contact?.zcrm_account_id){
        response.dataContact = await zohoService.getDataClientPaymentWithCompany(dataEstimate?.data?.estimate?.contact?.zcrm_account_id);
      }
      else{
        response.dataContact = await zohoService.getDataClientPayment(dataEstimate?.data?.estimate?.contact?.zcrm_contact_id);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const findDataPaymentClientContact = createAsyncThunk(
  `${name}/find-data-payment-client-contact`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await zohoService.getDataClientPayment(payload);
      if (response?.data?.error) {
        return rejectWithValue(response.data);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const findDataPaymentClientAccount = createAsyncThunk(
  `${name}/find-data-payment-client-account`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await zohoService.getDataClientPaymentWithCompany(payload);
      if (response?.data?.error) {
        return rejectWithValue(response.data);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const findSalesOrderById = createAsyncThunk(
  `${name}/find-salesorder-by-id`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await zohoService.getOrder(payload);
      if (response?.data?.error) {
        return rejectWithValue(response.data);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const findContactById = createAsyncThunk(
  `${name}/find-contact-zoho-by-id`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await zohoService.getContact(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const findContactByIdCRM = createAsyncThunk(
  `${name}/find-contact-zoho-by-id-CRM`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await zohoService.getContactCRM(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const zohoSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findContactById.fulfilled, (state, action) => {
      state.contact = action.payload.data?.contact;
    });
    builder.addCase(findContactByIdCRM.fulfilled, (state, action) => {
      state.contact = action.payload.data?.contact;
    });
    builder.addCase(findEstimateById.fulfilled, (state, action) => {
      state.estimate = action.payload.dataEstimate.data?.estimate;
      state.paymentClient = action.payload.dataContact.data?.data[0]?.En_compte;
    });
    builder.addCase(findSalesOrderById.fulfilled, (state, action) => {
      state.salesorder = action.payload.data?.salesorder;
    });
    builder.addCase(findDataPaymentClientContact.fulfilled, (state, action) => {
      state.paymentClient = action?.payload.data?.data[0]?.En_compte;
    });
    builder.addCase(findDataPaymentClientAccount.fulfilled, (state, action) => {
      state.paymentClient = action?.payload.data?.data[0]?.En_compte;
    })
  }
});

export const selectContact = (state: RootState) => state.zoho.contact;

export const selectEstimate = (state: RootState) => state.zoho.estimate;

export const selectSalesOrder = (state: RootState) => state.zoho.salesorder;

export const selectDataPaymentClient = (state: RootState) => state.zoho.paymentClient;
export default zohoSlice.reducer;
