import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Product, UnitOption } from '../../models';
import { productService } from '../../services';
import { Loading } from '../../types';

interface ProductState {
  products: Product[];
  unitOptions: UnitOption[];
  productsLoading: Loading;
}

const name = 'product';
const initialState: ProductState = {
  products: [],
  unitOptions: [],
  productsLoading: 'idle',
};

export const fetchProducts = createAsyncThunk(
  `${name}/list-management-products`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await productService.getProducts(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUnitOptions = createAsyncThunk(
  `${name}/list-management-unit-prices`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await productService.getUnitOptions(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const productSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.productsLoading = 'pending';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsLoading = 'idle';
        state.products = action.payload.data ?? [];
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.productsLoading = 'idle';
      });
    builder.addCase(fetchUnitOptions.fulfilled, (state, action) => {
      state.unitOptions = action.payload.data ?? [];
    });
  },
});

export const selectProducts = (state: RootState) => state.product.products;
export const selectProductsLoading = (state: RootState) =>
  state.product.productsLoading;
export const selectUnitOptions = (state: RootState) => state.product.unitOptions;

export default productSlice.reducer;
