import { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { Address } from 'types';
import { v4 as uuidv4 } from 'uuid';
import { convertAddress, getFullAddress, isObjectEmpty } from 'utils';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const Option = Select.Option;

interface SelectWithGoogleSuggestionProps {
  error?: boolean;
  defaultValue?: any;
  onSelect?: (address: Address) => void;
  onChange?: any;
  onBlur?: any;
  otherStyles?: any;
  disabled?: boolean;
  options: Address[];
  placeholder?: string;
  autoConvertAddress?: boolean;
  data?: any;
}

const SelectWithGoogleSuggestion = (props: SelectWithGoogleSuggestionProps) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 0,
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'fr' },
    },
  });
  const { disabled, onSelect, defaultValue, placeholder, autoConvertAddress } = props;
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [options, setOptions] = useState<Address[]>([]);
  const [loading, setIsloading] = useState(false);

  useEffect(() => {
    const updatedOptions: Address[] = props.options.map(option => ({
      ...option,
      fullAddress: getFullAddress(option),
    }));
    if (!isObjectEmpty(defaultValue) || isObjectEmpty(selectValue)) {
      let selectedOption = updatedOptions.find(i => i.fullAddress === getFullAddress(defaultValue));
      // add defaluveValue as an option to the list if it is not exist
      if (!selectedOption && updatedOptions.length && !isObjectEmpty(defaultValue)) {
        selectedOption = {
          address_id: uuidv4(),
          ...defaultValue,
          fullAddress: getFullAddress(defaultValue),
        };
        updatedOptions.unshift(selectedOption as Address);
      }
    }
    setOptions(updatedOptions);
  }, [props.options]);

  useEffect(() => {
    if (!isObjectEmpty(defaultValue) || isObjectEmpty(selectValue)) {
      const fullAddress = getFullAddress(defaultValue);
      if (fullAddress) {
        const selectedOption = options.find(i => i.fullAddress === getFullAddress(defaultValue));
        setSelectValue(selectedOption?.address_id);
      }
    } else if (defaultValue === null || defaultValue === undefined) {
      setSelectValue(null);
    }
  }, [defaultValue, options]);

  const handleSelectAddress = async (placeId: any) => {
    const selectedAddress = options?.find(
      (item) => item.address_id === placeId
    );
    if (selectedAddress) {
      setSelectValue(selectedAddress.address_id);
      getPlacePredictions({ input: '' });
      setInputValue('');
      onSelect && onSelect(selectedAddress);
    } else {
      placesService?.getDetails({ placeId }, async (placeDetails: any) => {
        let selectedAddress = convertAddress(placeDetails) as Address;
        if (!selectedAddress.isCompleted && autoConvertAddress) {
          setIsloading(true);
          const mapsResponse = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', { 
            params: { 
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, 
              latlng: `${selectedAddress.latitude},${selectedAddress.longitude}`
            }
          });
          const locations = mapsResponse?.data?.results;
          if (locations?.length) {
            for (let index = 0; index < locations.length; index++) {
              selectedAddress = convertAddress(locations[index]) as Address;  
              if (selectedAddress.isCompleted) {
                break;
              }
            }
          }
          setIsloading(false);
        }
        // check if the new address is equal to any existing addresses, use existed address instead of create a new one
        const isExist = options?.find(item => item.fullAddress === getFullAddress(selectedAddress))
        setInputValue('');
        getPlacePredictions({ input: '' });
        const newAddress: Address = isExist || {
          address_id: placeId,
          isCreate: true,
          ...selectedAddress,
        };
        if (!isExist) {
          setOptions((prevState) => ([newAddress, ...prevState]));
        }
        onSelect && onSelect(newAddress);
        setSelectValue(newAddress.address_id);
      });
    }
  };
  return (
    <Select
      showSearch
      listItemHeight={32}
      value={selectValue}
      disabled={disabled}
      placeholder={placeholder || 'Sélectionner'}
      filterOption={false}
      optionFilterProp="children"
      onSelect={handleSelectAddress}
      loading={isPlacePredictionsLoading || loading}
      onSearch={(value) => {
        getPlacePredictions({ input: value });
        setInputValue(value);
      }}
    >
      {options
        .filter((item) =>
        !inputValue || item.fullAddress?.toLowerCase().includes((inputValue ?? '').toLowerCase())
        )
        .map((item) => (
          <Option key={item.address_id} value={item.address_id}>
            {item.fullAddress}
          </Option>
        ))}
      {inputValue &&
        (placePredictions || []).map((d) => (
          <Option key={d.description} value={d.place_id}>
            {d.description}
          </Option>
        ))}
    </Select>
  );
};

export default SelectWithGoogleSuggestion;
