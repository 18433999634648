import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner, Main } from '../components';
import { AppRouteType } from '../types';

const NotFound = lazy(() => import('../pages/404'));
const CreateEstimate = lazy(() => import('../pages/Estimate/CreateEstimate'));
const UpdateEstimate = lazy(() => import('../pages/Estimate/UpdateEstimate'));
const CreateOrder = lazy(() => import('../pages/Order/CreateOrder'));
const UpdateOrder = lazy(() => import('../pages/Order/UpdateOrder'));

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routePage = {
  BASE: 0,
  GROUPE: 1,
  POINTS_DE_COLLECTS: 2
};

export const routes: AppRouteType[] = [
  {
    name: '404',
    path: '404',
    component: NotFound,
    isPublic: true
  },
  {
    name: 'Create Estimate',
    auth: true,
    path: '/',
    component: CreateEstimate,
    isPublic: true
  },
  {
    name: 'Update an Estimate',
    auth: true,
    path: '/:estimateId',
    component: UpdateEstimate
  },
  {
    name: 'Create Order',
    auth: true,
    path: '/order',
    component: CreateOrder,
    isPublic: true
  },
  {
    name: 'Update an Order',
    auth: true,
    path: '/order/:orderId',
    component: UpdateOrder
  }
];

export default function RoutesAppRoutes() {
  // authenticated routes
  const authenticatedRoutes = routes
    // .filter(
    //   (route) =>
    //     (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    // )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  return (
    <Main>
      <Routes>{authenticatedRoutes}</Routes>
    </Main>
  );
}
