
import { toast } from 'react-toastify';
import { Row, Col, Form, Input, Select, Divider, Spin, FormInstance, Tooltip, Checkbox, CheckboxProps, Switch, Typography, Button, UploadFile } from 'antd';
import { DeleteTwoTone, PlusCircleOutlined, ReloadOutlined, SyncOutlined, CopyOutlined, CheckOutlined, CloseOutlined, SearchOutlined, DeleteOutlined, FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { SelectWithGoogleSuggestion, SearchClient } from '../components';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppSelector } from '../store';
import { selectSales } from '../store/slices/sale.slices';
import { Address, ChantierAddress } from 'types';
import { Contact } from '../models';
import {
  CONTACT_TYPES,
  CREATE_ACCOUNT,
  CUSTOM_ORDER_FIELDS,
  CUSTOM_QUOTATION_FIELDS,
  ORDER_STATUSES,
  STATUSES,
  VALUES_PAYMENT_CLIENT,
  TRANSACTION_PROOF
} from '../utils/constant';
import CreateContact from './CreateContact';
import { convertAddress, getFullAddress } from 'utils';
import { selectDataPaymentClient } from 'store/slices/zoho.slices';
import axios from 'axios';
import { useDebounce } from 'hooks';
import FileUpload from './common/FileUpload';
import TransactionProof from './Order/TransactionProof';
import { zohoService } from 'services';

type LoadingContactType = 'cc' | 'reference_client' | null;

const ClientChantier = ({
  type,
  form,
  data,
  setData,
  isSubmitted,
  contact,
  disabled,
  lastModifyById,
  booksUserId,
  referentId,
  zcrmContactId,
  getContact,
  setInitialReferenceContactId,
  isSaveDraft = false,
  clientTypeBDC,
  allowChangeClient = true,
  isOpenTransactionModal = false,
  setIsOpenTransactionModal = () => null,
  onDataProofFile,
  proofFile,
  listFile,
  createAccount,
  autoConvertAddress = true,
}: {
  type: 'order' | 'estimate';
  form: FormInstance;
  data?: any | null;
  setData: Function;
  isSubmitted: boolean;
  contact?: Contact | null;
  disabled?: boolean;
  lastModifyById?: string | null;
  booksUserId?: string | null;
  referentId?: string | null;
  zcrmContactId?: string | null;
  getContact: (contact_id?: string) => Promise<void>;
  setInitialReferenceContactId?: Function;
  isSaveDraft?: boolean;
  clientTypeBDC?: String | null | undefined;
  allowChangeClient?: boolean;
  isOpenTransactionModal?: boolean;
  setIsOpenTransactionModal?: Dispatch<SetStateAction<boolean>>;
  onDataProofFile?: any;
  proofFile?: any;
  listFile?: any;
  createAccount?: string
  autoConvertAddress?: boolean;
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isOpenModalSearchClient, setIsOpenModalSearchClient] = useState(false);
  const [isChangeClient, setIsChangeClient] = useState(false);
  const [changedClient, setChangedClient] = useState<any>(null);
  const [isLoadingContact, setIsLoadingContact] = useState<LoadingContactType>(null);
  const [isCheckedPaymentClient, setIsCheckedPaymentClient] = useState<boolean>(false);
  const CUSTOM_FIELDS =
    type === 'order' ? CUSTOM_ORDER_FIELDS : CUSTOM_QUOTATION_FIELDS;
  const sales = useAppSelector(selectSales);
  const contactPersons = contact?.contact_persons ?? [];
  const cclibres = data?.custom_fields?.[CUSTOM_FIELDS.EMAILCC]?.length > 0
    ? data?.custom_fields?.[CUSTOM_FIELDS.EMAILCC]?.split(',')
      .map((item: string) => item.trim())
    : []
  const [emailcc, setEmailcc] = useState(cclibres);
  const MAX_EMAIL_ALLOWED = 5;
  const paymentClient = useAppSelector(selectDataPaymentClient);
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileTransactionProof, setFileTransactionProof] = useState<any>(null);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState<boolean>(false);
  useEffect(() => {
    if (createAccount == CREATE_ACCOUNT.CREATE_A_CONTACT || createAccount == CREATE_ACCOUNT.CREATE_CONTACT_ESTIMATE) {
      setIsLoadingModal(true);
      setIsOpenModalSearchClient(true);
      setIsLoadingModal(false);
    }
  }, []);
  useEffect(() => {
    if (sales?.length) {
      if (booksUserId) {
        form.setFieldValue(
          ['custom_fields', CUSTOM_FIELDS.LAST_MODIFY_BY_ID],
          booksUserId
        );
      } else {
        const lastModifySale = sales?.find(
          (i) => i.crm_user_id === lastModifyById
        );
        form.setFieldValue(
          ['custom_fields', CUSTOM_FIELDS.LAST_MODIFY_BY_ID],
          lastModifySale?.books_user_id
        );
      }
      if (referentId && !data?.salesperson_id) {
        const referentSale = sales?.find((i) => i.crm_user_id === referentId);
        form.setFieldValue(
          'salesperson_id',
          referentSale?.books_salesperson_id
        );
      }
      if (data?.salesperson_id) {
        form.setFieldValue('salesperson_id', data?.salesperson_id);
      }
      if (!data?.custom_field_hash?.cf_vendeur_unformatted) {
        const lastModifySale = sales?.find(
          (i) => i.crm_user_id === lastModifyById
        );
        form.setFieldValue(
          ['custom_fields', CUSTOM_FIELDS.VENDEUR],
          lastModifySale?.books_user_id
        );
      }
    }
  }, [sales, data?.salesperson_id, lastModifyById]);

  const setInitialValue = async () => {
    if (contact?.customer_sub_type === CONTACT_TYPES.BUSINESS) {
      const initialValueContactPersons = data?.custom_fields?.[CUSTOM_FIELDS.BOOKS_CONTACT_ID]
        ? data?.custom_fields?.[CUSTOM_FIELDS.BOOKS_CONTACT_ID]
        : contactPersons?.length
          ? zcrmContactId ? contactPersons.find(x => x.zcrm_contact_id === zcrmContactId)?.contact_person_id
            : contactPersons[0].contact_person_id
          : undefined;
      form.setFieldsValue({ contact_persons: initialValueContactPersons });

      let cc_contact_persons: any[] = data?.contact_persons ?? [];
      cc_contact_persons = cc_contact_persons.filter((val: any) => !initialValueContactPersons.includes(val));
      form.setFieldsValue({ cc_contact_persons });

      const itemReferentClient = contactPersons.find(x => x.contact_person_id === initialValueContactPersons);
      setInitialReferenceContactId && setInitialReferenceContactId(itemReferentClient?.zcrm_contact_id ?? null);
      if (itemReferentClient?.zcrm_contact_id) {
        form.setFieldValue(['custom_fields', CUSTOM_FIELDS.REFERENCE_CLIENT], itemReferentClient?.zcrm_contact_id);
      }

    }

    if (contact?.contact_name) {
      form.setFieldsValue({ customer_name: contact?.contact_name });
    }
    if (data?.custom_fields?.[CUSTOM_FIELDS.REFERENCE_BDC_CLIENT]) {
      form.setFieldValue(
        ['custom_fields', CUSTOM_FIELDS.REFERENCE_BDC_CLIENT],
        data?.custom_fields?.[CUSTOM_FIELDS.REFERENCE_BDC_CLIENT]
      );
    }
    if (data?.custom_fields?.[CUSTOM_FIELDS.CONTACT_CHANTIER]) {
      form.setFieldValue(
        ['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER],
        data?.custom_fields?.[CUSTOM_FIELDS.CONTACT_CHANTIER]
      );
    }
    if (data?.billing_address?.address) {
      form.setFieldValue(
        ['billing_address', 'address'],
        data?.billing_address?.address
      );
    }
    if (data?.chantier_address?.address) {
      form.setFieldValue(
        ['custom_fields', CUSTOM_FIELDS.ADDRESS_CHANTIER],
        data?.chantier_address?.address
      );
    }
    if (data?.custom_fields?.[CUSTOM_FIELDS.EMAILCC]) {
      form.setFieldValue(
        ['custom_fields', CUSTOM_FIELDS.EMAILCC],
        data?.custom_fields?.[CUSTOM_FIELDS.EMAILCC])
    }
    if (data?.custom_field_hash?.cf_generer_lien_de_paiement === VALUES_PAYMENT_CLIENT.NE_PAS_GENERER_LE_LIEN) {
      setIsCheckedPaymentClient(false);
      setData({ generer_lien_de_paiement: VALUES_PAYMENT_CLIENT.NE_PAS_GENERER_LE_LIEN });
    }
    else if (data?.custom_field_hash?.cf_generer_lien_de_paiement === VALUES_PAYMENT_CLIENT.GENERER_LE_LIEN) {
      setIsCheckedPaymentClient(true);
      setData({ generer_lien_de_paiement: VALUES_PAYMENT_CLIENT.GENERER_LE_LIEN });
    }
  };

  const submitFileProof = (file:any) => {
    setFileTransactionProof(file);
    onDataProofFile(file);
  }

  const setChangeClientValue = async () => {
    if (contact?.customer_sub_type === CONTACT_TYPES.BUSINESS) {
      let contactPerson;
      if (changedClient?.contact_person_id) {
        contactPerson = contact?.contact_persons.find(person => person.contact_person_id === changedClient?.contact_person_id);
      } else {
        contactPerson = contact?.contact_persons[0];
      }
      form.setFieldsValue({ contact_persons: contactPerson?.contact_person_id});
      form.setFieldValue( ['custom_fields', CUSTOM_FIELDS.REFERENCE_CLIENT], contactPerson?.zcrm_contact_id );
    }else{
      form.setFieldsValue({ contact_persons: null});
      form.setFieldValue( ['custom_fields', CUSTOM_FIELDS.REFERENCE_CLIENT], null );
    }

    if (contact?.contact_name) {
      form.setFieldsValue({ customer_name: contact?.contact_name });
    }
    form.setFieldsValue({ cc_contact_persons: [] });
    form.setFieldValue(
      ['custom_fields', CUSTOM_FIELDS.REFERENCE_BDC_CLIENT],
      null
    );
    if (data?.billing_address?.address) {
      form.setFieldValue(
        ['billing_address', 'address'],
        data?.billing_address?.address
      );
    }
    form.setFieldValue(
      ['custom_fields', CUSTOM_FIELDS.EMAILCC],
      null
    );
    form.setFieldValue(
      ['cf_emailcc'],
      []
    );
    setEmailcc(null);
    form.setFieldValue(
      ['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER],
      null
    );
    setData({ chantier_address: null });
    form.setFieldValue(
      ['custom_fields', CUSTOM_FIELDS.ADDRESS_CHANTIER],
     ""
    );
    handlChangeSwitchGPS(false);
    setIsChangeClient(false);
  };
  const setInitialFileUpload = () => {
    let dataListFile: any[] = [];
    data?.documents?.forEach((item: any) => {
      dataListFile.push({
        ...item,
        uid: item.document_id,
        name: item.file_name.replace(".pdf", ""),
        status_file: "UPDATE",
        isLoading: false,
      });
    });
    setFileList(dataListFile);
  };

  useEffect(() => {
    if(data?.custom_field_hash?.cf_gps) {
      handlChangeGPS(data?.lat_long_gps_address);
    }
  }, [
    data?.custom_field_hash?.cf_gps
  ]);

  useEffect(() => {
    if (!isChangeClient) {
      setInitialValue();
    } else {
      setChangeClientValue();
    }
  }, [
    //contact,
    contactPersons,
    // data?.contact_persons,
    // contact?.contact_name,
    // form,
    // changedClient,
  ]);
  useEffect(() => {
    setFileList(listFile);

  }, [ data?.documents ]);

  useEffect(() => {
    if (!data?.salesorder_id || data?.salesorder_id == "") {
      setData({ file_upload: fileList });
    }
  }, [fileList]);

  useEffect(() => {
    setFileTransactionProof(proofFile);
  }, [proofFile]);

  const handleChangeReferentClient = () => {
    if (form.getFieldValue('contact_persons')) {
      const itemReferentClient = contactPersons.find(x => x.contact_person_id === form.getFieldValue('contact_persons'));
      if (itemReferentClient?.zcrm_contact_id) {
        form.setFieldValue(['custom_fields', CUSTOM_FIELDS.REFERENCE_CLIENT], itemReferentClient?.zcrm_contact_id);
      }
    }
  };
  const handleChangeCCEmail = (value: any) => {
    if (value.length > MAX_EMAIL_ALLOWED) {
      toast.error(
        <div>
          Les cc gratuits ont atteint leur quantité maximale
        </div>
      );
    }
    const limitedEmail = value?.slice(0, MAX_EMAIL_ALLOWED).map((option: any) => (option));
    form.setFieldsValue({ [CUSTOM_FIELDS.EMAILCC]: limitedEmail })
    form.setFieldValue(['custom_fields', CUSTOM_FIELDS.EMAILCC], limitedEmail)
    setEmailcc(limitedEmail)
  }
  const handleSelectFacturationAddress = async (address: Address) => {
    form.setFieldValue(['billing_address', 'address'], address.fullAddress);
    setData({ billing_address: address });
  };

  const handleSelectChantierAddress = async (address: Address) => {
    form.setFieldValue(
      ['custom_fields', CUSTOM_FIELDS.ADDRESS_CHANTIER],
      address.fullAddress
    );
    const newAddress: ChantierAddress = { ...address };
    if (newAddress.isCreate) {
      newAddress.Deal_Name = address.fullAddress?.toUpperCase();
      newAddress.Adresse_chantier = address?.address;
      newAddress.Code_postal_chantier = address.zip;
      newAddress.Ville_chantier = address.city;
      newAddress.Longitude = address.longitude;
      newAddress.Latitude = address.latitude;
      newAddress.Adresse_compl_te = address.fullAddress;
    }
    setData({ chantier_address: newAddress });
  };

  const reloadContact = async (type: 'cc' | 'reference_client') => {
    setIsLoadingContact(type);
    try {
      await getContact();
      setIsLoadingContact(null);
    } catch (error) {
      toast.error('Erreur');
      setIsLoadingContact(null);
    }
  };
  const handleCopyData = (dataCopy: string | undefined) => {
    dataCopy = dataCopy?.toString();
    if (dataCopy !== undefined && dataCopy.length > 0) {
      navigator.clipboard.writeText(dataCopy ? dataCopy : "");
      toast.success(
        <div>
          Copié dans le presse-papier
        </div>
      );
    }
  };
  const handleCopyDataAccount = () => {
    let books_user_id_select = form.getFieldValue(['custom_fields', CUSTOM_FIELDS.LAST_MODIFY_BY_ID]);
    let sale = sales.find(function (obj) {
      return books_user_id_select === obj.books_user_id;
    });
    handleCopyData(sale?.name);
  };
  const handleCopyDataSalesPerson = () => {
    let salesperson_id = form.getFieldValue('salesperson_id');
    let sale = sales.find(function (obj) {
      return salesperson_id === obj.books_salesperson_id;
    });
    handleCopyData(sale?.name);
  };
  const handleCopyDataFollowFormName = (FieldNameForm: any) => {
    handleCopyData(form.getFieldValue(FieldNameForm));
  };
  const handleShowPopUpSearchClient = () => {
    if (allowChangeClient) {
      setIsLoadingModal(true);
      setIsOpenModalSearchClient(true);
      setIsLoadingModal(false);
    }
  }

  const handleCopyDataCCLibres = (FieldNameForm: any) => {
    handleCopyData(form.getFieldValue(FieldNameForm));
  };
  const handleCopyDataContactPersons = () => {
    let contact_persons_id = form.getFieldValue('contact_persons');
    let contactPerson = contactPersons.find(function (obj) {
      return contact_persons_id === obj.contact_person_id;
    });
    handleCopyData(contactPerson?.email);
  };

  const handleContactChantierChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedInput = inputValue.replace(/(\d{2}(?=\d))/g, '$1 ');

    form.setFieldValue(['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER], formattedInput);
  };
  useEffect(() => {
    if (paymentClient === 'Paiement requis') {
      setIsCheckedPaymentClient(true)
      setData({ generer_lien_de_paiement: VALUES_PAYMENT_CLIENT.GENERER_LE_LIEN });
    }
  }, [paymentClient]);
  const handleDownloadFile = async (item: any) => {
    console.log(item);
    // Check update order
    if(item.status_file === "UPDATE" && data?.salesorder_id && data?.salesorder_id !== "") {
      window.open(process.env.REACT_APP_BASE_API + `/download-document-salesorder?salesorder_id=${data?.salesorder_id}&document_id=${item?.document_id}&file_name=${item?.file_name}`);
    }
  };
  const handleDeleteFile = async (item: any) => {
    if (item.status_file === "CREATE") {
      const updatedFileList = fileList.filter((file) => file.uid !== item.uid);
      setFileList(updatedFileList);
    }
    // Check update order
    else if (item.status_file === "UPDATE" && data?.salesorder_id && data?.salesorder_id !== "" && disabledButtonDelete === false) {
      console.log(item);
      // Call API delete file
      try {
        // Call API delete file
        item.isLoading = true;
        setDisabledButtonDelete(true);
        handleRenderUIListFileUpload();
        const { rs } = await zohoService.deleteFileToSalesorder(data?.salesorder_id, item.document_id);
        const updatedFileList = fileList.filter((file) => file.uid !== item.uid);
        const newFileList: UploadFile[] = [];
        updatedFileList.forEach((item: any) => {
          newFileList.push({
            ...item,
          });
        });
        setFileList(newFileList);
        setDisabledButtonDelete(false);
      } catch (error) {
        toast.error('Erreur');
      }
    }
  };
  const handleRenderUIListFileUpload = () => {
    const newFileList: UploadFile[] = [];
    fileList.forEach((item: any) => {
      newFileList.push({
        ...item,
      });
    });
    setFileList(newFileList);
  };


  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsCheckedPaymentClient(e.target.checked);
    if (e.target.checked === true) {
      setData({ generer_lien_de_paiement: VALUES_PAYMENT_CLIENT.GENERER_LE_LIEN });
    }
    else {
      setData({ generer_lien_de_paiement: VALUES_PAYMENT_CLIENT.NE_PAS_GENERER_LE_LIEN });
    }
  };
  const handlChangeSwitchGPS = async (is_gps_address: Boolean) => {
    setData({ is_gps_address: is_gps_address });
    if (!is_gps_address) {
      setData({ lat_long_gps_address: '' });
      setData({ gps_address: '' });
    }
  };
  const handlChangeGPS = (inputValue: string) => {
    form.setFieldValue('lat_long_gps_address', inputValue);
    setData({ lat_long_gps_address: inputValue });
    fetchChangeGPS(inputValue);
  }
  const fetchChangeGPS = useDebounce(async (inputValue: string) => {
    if (!inputValue || inputValue.length === 0) {
      return;
    }
    const [lat, long] = inputValue.split(", ");
    try {
      const mapsResponse = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          latlng: `${lat},${long}`
        }
      });
      const locations = mapsResponse?.data?.results[0];
      const newAddress = convertAddress(locations) as Address;
      newAddress.isCreate = true;
      // setData({ billing_address: newAddress });
      setData({ gps_address: newAddress?.fullAddress });
      // Find full address in contact_address
      const contactAddressOld = contact?.contact_address?.find(
        (i) => i.fullAddress === newAddress?.fullAddress
      );
      if (!contactAddressOld) {
        let newChantierAddress: ChantierAddress = { ...newAddress };
        newChantierAddress.isCreate = true;
        newChantierAddress.Deal_Name = newAddress.fullAddress?.toUpperCase();
        newChantierAddress.Adresse_chantier = newAddress.address;
        newChantierAddress.Code_postal_chantier = newAddress.zip;
        newChantierAddress.Ville_chantier = newAddress.city;
        newChantierAddress.Longitude = newAddress.longitude;
        newChantierAddress.Latitude = newAddress.latitude;
        newChantierAddress.Adresse_compl_te = newAddress.fullAddress;
        setData({ chantier_address: newChantierAddress });
        console.log('newChantierAddress');
        console.log(data);
      } else {
        console.log('contactAddressOld update');
        setData({ chantier_address: contactAddressOld });
      }
    } catch (error) {
      console.log(error);
      setData({ gps_address: '' });
      // setData({ billing_address: {} });
      setData({ chantier_address: {} });
    }
  }, 1000);

  const handleSelectClient = async (record: any) => {
    if (record.zcrm_contact_id !== zcrmContactId) {
      setIsLoadingModal(true);
      setIsChangeClient(true);
      setChangedClient(record);
      await getContact(record.zcrm_contact_id);
      setIsLoadingModal(false);
    }
    setIsOpenModalSearchClient(false);
  };
  return (
    <section className='mt-2 section client-chantier'>
      <CreateContact
        lastModifyById={lastModifyById as string}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        customerId={contact?.zcrm_account_id}
        sales={sales}
        getContact={getContact}
      />
      <Row gutter={[16, 0]}>
        <Col
          xs={{ span: 12 }}>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                label='Vendeur'
                name={['custom_fields', CUSTOM_FIELDS.VENDEUR]}
                initialValue={data?.custom_field_hash?.cf_vendeur_unformatted}
                rules={[{ required: true }]}>
                <Select
                  placeholder='Sélectionner'
                  showSearch
                  filterOption={(input, option) =>
                    ((option?.label ?? '') as string)
                      .toLowerCase()
                      .includes((input ?? '').toLowerCase())
                  }
                  options={sales?.map((option) => ({
                    value: option.books_user_id,
                    label: option.name
                  }))}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 4 }}>
              <CopyOutlined
                onClick={() => handleCopyDataAccount()}
                style={{ fontSize: 24, color: '#95C515' }}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 12 }}>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                label='Référent'
                name='salesperson_id'
                initialValue={data?.salesperson_id}
                rules={[{ required: true }]}>
                <Select
                  placeholder='Sélectionner'
                  showSearch
                  listItemHeight={32}
                  filterOption={(input, option) =>
                    ((option?.label ?? '') as string)
                      .toLowerCase()
                      .includes((input ?? '').toLowerCase())
                  }
                  options={sales?.map((option) => ({
                    value: option.books_salesperson_id,
                    label: option.name
                  }))}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 4 }}>
              <CopyOutlined
                onClick={() => handleCopyDataSalesPerson()}
                style={{ fontSize: 24, color: '#95C515' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider className='horizontal-bar' />
      <Row gutter={[16, 0]}>
        <Col
          xs={{ span: 12 }}>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              {
                type === 'estimate' ? (
                  <Form.Item
                    label='Client'
                    tooltip={{
                      title: 'Generer lien de paiement',
                      color: '#E0EDC2',
                      icon:
                        <Checkbox
                          checked={isCheckedPaymentClient}
                          onChange={onChange}
                          disabled={paymentClient === 'Paiement requis'}
                        >
                        </Checkbox>
                    }}
                    name='customer_name'
                    initialValue={data?.customer_name}
                    rules={[{ required: true }]}>
                    <Input disabled={disabled} />
                  </Form.Item>
                ) :
                  (
                    <Form.Item
                      label='Client'
                      name='customer_name'
                      initialValue={data?.customer_name}
                      rules={[{ required: true }]}>
                      <Input disabled={disabled} />
                    </Form.Item>
                  )
              }
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 6 }}>
              <SearchOutlined
                disabled={!allowChangeClient}
                onClick={() => handleShowPopUpSearchClient()}
                style={allowChangeClient ? { fontSize: 24, color: '#95C515' } : { fontSize: 24, color: '#d9d9d9', cursor: 'not-allowed' }}
              />
              <CopyOutlined
                onClick={() => handleCopyDataFollowFormName('customer_name')}
                style={{ fontSize: 24, color: '#95C515', marginLeft: 10 }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                label='Référent Client'
                name='contact_persons'
                // initialValue={data?.contact_persons?.[0]}
                rules={[
                  {
                    required: contact?.customer_sub_type === CONTACT_TYPES.BUSINESS
                  }
                ]}>
                <Select
                  placeholder='Sélectionner'
                  disabled={
                    contact?.customer_sub_type !== CONTACT_TYPES.BUSINESS ||
                    disabled
                  }
                  onChange={handleChangeReferentClient}
                  showSearch
                  listItemHeight={32}
                  filterOption={(input, option) =>
                    ((option?.label ?? '') as string)
                      .toLowerCase()
                      .includes((input ?? '').toLowerCase())
                  }

                  options={contactPersons?.map((option: any) => ({
                    value: option.contact_person_id,
                    label: [
                      [option.first_name, option.last_name]
                        .filter((i) => i)
                        .join(' '),
                      option.email,
                    ]
                      .filter((i) => i)
                      .join(' - '),
                  }))}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 6 }}>
              <ReloadOutlined
                spin={isLoadingContact === 'reference_client'}
                onClick={() =>
                  contact?.customer_sub_type === 'business' &&
                  !disabled &&
                  reloadContact('reference_client')
                }
                style={{ fontSize: 24, color: '#95C515' }}
              />
              <PlusCircleOutlined
                onClick={() =>
                  contact?.customer_sub_type === 'business' &&
                  !disabled &&
                  setIsOpenModal(true)
                }
                style={{ fontSize: 24, color: '#95C515', marginLeft: 10 }}
              />
              <CopyOutlined
                onClick={() => handleCopyDataContactPersons()}
                style={{ fontSize: 24, color: '#95C515', marginLeft: 10 }}
              />
            </Col>
          </Row>
          <Form.Item name={['custom_fields', CUSTOM_FIELDS.REFERENCE_CLIENT]} hidden />
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                label='CC'
                name='cc_contact_persons'
                initialValue={data?.cc_contact_persons}
              >
                <Select
                  placeholder='Sélectionner'
                  showSearch
                  mode='multiple'
                  listItemHeight={32}
                  disabled={contact?.customer_sub_type !== 'business' || disabled}
                  removeIcon={<DeleteTwoTone style={{ paddingLeft: 4, fontSize: 12 }} twoToneColor='#ff4d4f' />}
                  filterOption={(input, option) =>
                    ((option?.label ?? '') as string)
                      .toLowerCase()
                      .includes((input ?? '').toLowerCase())
                  }
                  options={contactPersons?.map((option: any) => ({
                    value: option.contact_person_id,
                    label: [
                      [option.first_name, option.last_name]
                        .filter((i) => i)
                        .join(' '),
                      option.email,
                    ]
                      .filter((i) => i)
                      .join(' - '),
                  }))}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 6 }}>
              <ReloadOutlined
                spin={isLoadingContact === 'cc'}
                onClick={() =>
                  contact?.customer_sub_type === 'business' &&
                  !disabled &&
                  reloadContact('cc')
                }
                style={{ fontSize: 24, color: '#95C515' }}
              />
              <PlusCircleOutlined
                onClick={() =>
                  contact?.customer_sub_type === 'business' &&
                  !disabled &&
                  setIsOpenModal(true)
                }
                style={{ fontSize: 24, color: '#95C515', marginLeft: 10 }}
              />
            </Col>
          </Row>
          {
            type === 'estimate' ?
              <Row gutter={[16, 0]}>
                <Col
                  xs={{ span: 18 }}>
                  <Form.Item
                    label='CC libres (limité à 5)'
                    name='cf_emailcc'
                    initialValue={emailcc}
                  >
                    <Select
                      placeholder='Sélectionner'
                      disabled={disabled}
                      showSearch
                      mode="tags"
                      listItemHeight={32}
                      removeIcon={<DeleteTwoTone style={{ paddingLeft: 4, fontSize: 12 }} twoToneColor='#ff4d4f' />}
                      tokenSeparators={[","]}
                      onChange={handleChangeCCEmail}
                      defaultValue={emailcc}
                      options={emailcc?.map((option: any) => ({
                        value: option,
                        label: option
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col
                  style={{ paddingTop: 20 }}
                  xs={{ span: 6 }}>
                  <CopyOutlined
                    onClick={() => handleCopyDataCCLibres(['custom_fields', CUSTOM_FIELDS.EMAILCC])}
                    style={{ fontSize: 24, color: '#95C515' }}
                  />
                </Col>
              </Row>
              :
              <Row gutter={[16, 0]}>
                <Col
                  xs={{ span: 18 }}>
                  <Form.Item
                    label='Adresse facturation'
                    name={['billing_address', 'address']}
                    initialValue={data?.billing_address?.address}
                    rules={[
                      {
                        required: true,
                        message: 'Adresse facturation is required',
                        validateTrigger: 'onSubmit',
                        validator: (_, value) => {
                          if ( isSubmitted && (type==='order') && (!data?.billing_address?.city || !data?.billing_address?.zip)) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}>
                    <SelectWithGoogleSuggestion
                      defaultValue={data?.billing_address}
                      disabled={disabled}
                      data={data}
                      options={contact?.contact_address ?? []}
                      onSelect={handleSelectFacturationAddress}

                    />
                  </Form.Item>
                </Col>
                <Col
                  style={{ paddingTop: 20 }}
                  xs={{ span: 6 }}>
                  <CopyOutlined
                    onClick={() => handleCopyData(getFullAddress(data?.billing_address))}
                    style={{ fontSize: 24, color: '#95C515' }}
                  />
                </Col>
              </Row>
          }

        </Col>
        <Col
          style={{ borderLeft: '1px solid #ccc' }}
          xs={{ span: 12 }}>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 18 }}>
              {clientTypeBDC && clientTypeBDC !== '' && clientTypeBDC != null && clientTypeBDC !== 'Aucun' &&
                <Form.Item>
                  <Typography.Title level={5} className='ma-0'>{`Client ${clientTypeBDC}`}</Typography.Title>
                </Form.Item>
              }
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              <Form.Item
                label='Reference BDC client'
                initialValue={
                  data?.custom_fields?.[CUSTOM_FIELDS.REFERENCE_BDC_CLIENT]
                }
                name={['custom_fields', CUSTOM_FIELDS.REFERENCE_BDC_CLIENT]}>
                <Input
                  placeholder='Reference BDC client'
                  disabled={disabled && ((type === 'order' && data?.status !== ORDER_STATUSES.open.key) || type === 'estimate')} />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 1 }}>
              <CopyOutlined
                onClick={() => handleCopyDataFollowFormName(['custom_fields', CUSTOM_FIELDS.REFERENCE_BDC_CLIENT])}
                style={{ fontSize: 24, color: '#95C515' }}
              />
            </Col>
            <Col
              xs={{ span: 4 }} className='pt-4'>
              {
                type === 'order' &&
                <FileUpload salesorderId={data?.salesorder_id} fileListProp={fileList} setFileListProp={setFileList} disabledButtonDeleteProp={disabledButtonDelete} setDisabledButtonDeleteProp={setDisabledButtonDelete} />
              }
            </Col>
          </Row>
          {
            fileList?.length > 0 &&
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 18 }}>
                <Form.Item>
                  <Row gutter={[16, 0]}>
                    {fileList?.filter((item) => !(item.name.includes(TRANSACTION_PROOF))).map((item, index) => (
                      <Col key={index} xs={{ span: 12 }}>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <a onClick={() => handleDownloadFile(item)}>
                            <FilePdfOutlined style={{ color: '#95C515' }} />
                            <span className='ml-1 mr-1' style={{ color: '#95C515' }}>{item.name}</span>
                          </a>
                          {
                            item.isLoading ?
                              <LoadingOutlined />
                              :
                              <DeleteOutlined onClick={() => handleDeleteFile(item)} style={{ cursor: 'pointer', color: 'red' }} />
                          }
                        </span>
                      </Col>
                    ))}
                    {fileList?.filter((item) => (item.name.includes(TRANSACTION_PROOF))).map((item, index) => (
                      <Col key={index} xs={{ span: 12 }}>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <FilePdfOutlined style={{ color: '#95C515' }} />
                          <span className='ml-1 mr-1' style={{ color: '#95C515' }}>{item.name}</span>
                          {
                            item.isLoading ?
                              <LoadingOutlined />
                              :
                              <DeleteOutlined onClick={() => handleDeleteFile(item)} style={{ cursor: 'pointer', color: 'red' }} />
                          }
                        </span>
                      </Col>
                    ))}
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          }
          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              {type === 'order' ? (
                <Form.Item
                  label='Contact sur place'
                  initialValue={data?.custom_fields?.[CUSTOM_FIELDS.CONTACT_CHANTIER]}
                  name={['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER]}
                  rules={[
                    { required: type === 'order' ? true : false },
                    () => ({
                      validator(_, value) {
                        if (!value) return Promise.reject();
                        const pattern = /^(\+\d{1,3}[- ]?)?[\d\s]+$/;
                        const valid = pattern.test(value);
                        if (!valid) return Promise.reject();
                        return Promise.resolve();
                      }
                    })
                  ]}>
                  <Input placeholder='Contact sur place' onChange={handleContactChantierChange} />
                </Form.Item>
              ) :
                (
                  <Form.Item
                    label='Contact sur place'
                    initialValue={data?.custom_fields?.[CUSTOM_FIELDS.CONTACT_CHANTIER]}
                    name={['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          const pattern = /^(\+\d{1,3}[- ]?)?[\d\s]+$/;
                          const valid = pattern.test(value);
                          if (value && !valid) return Promise.reject();
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <Input disabled={disabled} placeholder='Contact sur place' onChange={handleContactChantierChange} />
                  </Form.Item>
                )}
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 6 }}>
              <CopyOutlined
                onClick={() => handleCopyDataFollowFormName(['custom_fields', CUSTOM_FIELDS.CONTACT_CHANTIER])}
                style={{ fontSize: 24, color: '#95C515' }}
              />
            </Col>
          </Row>
          {
            type === 'estimate' &&
            <Row gutter={[16, 0]}>
              <Col
                xs={{ span: 18 }}>
                <Form.Item
                  label='Adresse facturation'
                  name={['billing_address', 'address']}
                  initialValue={data?.billing_address?.address}>
                  <SelectWithGoogleSuggestion
                    defaultValue={data?.billing_address}
                    disabled={disabled}
                    data={data}
                    options={contact?.contact_address ?? []}
                    onSelect={handleSelectFacturationAddress}
                  />
                </Form.Item>
              </Col>
              <Col
                style={{ paddingTop: 20 }}
                xs={{ span: 6 }}>
                <CopyOutlined
                  onClick={() => handleCopyData(getFullAddress(data?.billing_address))}
                  style={{ fontSize: 24, color: '#95C515' }}
                />
              </Col>
            </Row>
          }

          <Row gutter={[16, 0]}>
            <Col
              xs={{ span: 18 }}>
              {
                data?.is_gps_address ? (
                  <Form.Item
                    label='Adresse Chantier'
                    initialValue={data?.lat_long_gps_address}
                    rules={[
                      { required: data?.is_gps_address && !isSaveDraft },
                      () => ({
                        validator(_, value) {
                          const invalid = data?.is_gps_address && !isSaveDraft && (!data?.gps_address || data?.gps_address == '' || data?.gps_address.trim().length == 0);
                          if (invalid) return Promise.reject();
                          return Promise.resolve();
                        }
                      })
                    ]}
                    validateStatus={
                      isSubmitted &&
                        (!data?.gps_address || data?.gps_address == '' || data?.gps_address.trim().length == 0)
                        && !isSaveDraft
                        ? 'error'
                        : ''
                    }
                    name={['lat_long_gps_address']}>
                    <Input onChange={(e) => handlChangeGPS(e.target.value)} value={data?.lat_long_gps_address} disabled={disabled} />
                    <div>{data?.gps_address}</div>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Adresse Chantier'
                    name={['custom_fields', CUSTOM_FIELDS.ADDRESS_CHANTIER]}
                    initialValue={data?.chantier_address?.address}
                    rules={[
                      {
                        required: true,
                        message: 'Chantier address is required',
                        validateTrigger: 'onSubmit',
                        validator: (_, value) => {
                          const { city, zip, address } = data?.chantier_address || {};
                            const isInvalid = (type === 'order' && (!city || !zip || !address)) || (!isSaveDraft && (!city || !zip));
                            return isInvalid ? Promise.reject() : Promise.resolve();
                        }
                      }
                    ]}>
                    <SelectWithGoogleSuggestion
                      defaultValue={((!isChangeClient && data?.chantier_address?.address != null) ? data?.chantier_address : undefined)}
                      disabled={disabled}
                      autoConvertAddress
                      options={contact?.crm_address ?? []}
                      onSelect={handleSelectChantierAddress}
                    />
                    {/* <GoogleAutocomplete
                        disabled={disabled}
                        defaultAddress={data?.chantier_address?.address}
                        onSelect={handleSelectChantierAddress}
                        onChange={handleChangeChantierAddress}
                      /> */}
                  </Form.Item>
                )
              }
            </Col>
            <Col
              style={{ paddingTop: 20 }}
              xs={{ span: 1 }}>
              <CopyOutlined
                onClick={() => handleCopyData(getFullAddress(data?.chantier_address))}
                style={{ fontSize: 24, color: '#95C515' }}
              />
            </Col>
            <Col
              xs={{ span: 4 }}>
              <Form.Item
                style={{ justifyContent: 'nomal', position: 'absolute' }}
                initialValue={data?.is_gps_address}>
                <Switch
                  disabled={disabled}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={data?.is_gps_address}
                  defaultChecked={data?.is_gps_address}
                  onChange={(e) => {
                    handlChangeSwitchGPS(e.valueOf())
                  }}
                />
                <label style={{ position: 'absolute', marginTop: '3px', marginLeft: '5px' }}><b>GPS</b></label>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <TransactionProof
        isOpenModal={isOpenTransactionModal}
        onSubmit={submitFileProof}
        setIsOpenModal={setIsOpenTransactionModal}
        salesorderId={data?.salesorder_id}
        file={fileTransactionProof}
        onHandleDownloadProof={(file) => handleDownloadFile(file)}
      />
      <Spin spinning={isLoadingModal}>
        <SearchClient
          onSelectClient={handleSelectClient}
          open={isOpenModalSearchClient}
          setOpen={setIsOpenModalSearchClient}
          contact={contact}
          lastModifyById={lastModifyById}
          referentId={referentId}
          getContact = {getContact}
          createAccount={createAccount}
          setIsChangeClient ={setIsChangeClient}
          setChangedClient={setChangedClient}
       />
      </Spin>

    </section >
  );
};
export default ClientChantier;
