import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
type PaginationType = {
  estimate_id?: string | null;
  order_id?: string | null;
};
export default function useQueryParams<T>(): [query: T & PaginationType] {
  const [searchParams] = useSearchParams();
  const query = useMemo(() => {
    const result: { [key: string]: any } = Object.fromEntries([
      ...searchParams
    ]);
    return result;
  }, [searchParams]) as T & PaginationType;

  return [query];
}
