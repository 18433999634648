import { ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';
import Sidebar from './Sidebar';
const { Content } = AntdLayout;

const Main = ({ children }: { children: ReactNode }) => {
  return (
    <AntdLayout className='layout'>
      <Sidebar />
      <Content className='main-content'>{children}</Content>
    </AntdLayout>
  );
};
export default Main;
