import { useState } from "react";

export default function useDebounce(func: Function, delay = 200) {
  const [timer, setTimer] = useState<any>(null);
  return (...args: any) => {
    clearTimeout(timer ?? 0);
    let _temp = setTimeout(() => {
      func(...args);
    }, delay);
    setTimer(_temp);
  };
}