import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Prestation } from '../../models';
import { prestationService } from '../../services';
import { Loading } from '../../types';

interface PrestationState {
  prestations: Prestation[];
  prestationsLoading: Loading;
}

const name = 'prestation';
const initialState: PrestationState = {
  prestations: [],
  prestationsLoading: 'idle'
};

export const fetchPrestations = createAsyncThunk(
  `${name}/list-management-prestations`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await prestationService.getPrestations(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const prestationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrestations.pending, (state) => {
        state.prestationsLoading = 'pending';
      })
      .addCase(fetchPrestations.fulfilled, (state, action) => {
        state.prestationsLoading = 'idle';
        state.prestations = action.payload.data ?? [];
      })
      .addCase(fetchPrestations.rejected, (state) => {
        state.prestationsLoading = 'idle';
      });
  }
});

export const selectPrestations = (state: RootState) =>
  state.prestation.prestations;
export const selectPrestationsLoading = (state: RootState) =>
  state.prestation.prestationsLoading;

export default prestationSlice.reducer;
