import axios from 'axios';
const zohoRequester = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});
zohoRequester.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
zohoRequester.interceptors.response.use(
  function (response: any) {
    return Promise.resolve(response.data);
  },
  function (error: any) {
    if (error.response) {
      error.status = error.response.status;
      error.message = error.response.statusText;
      if (error.response.data && error.response.data.error) {
        error.message = error.response.data.error;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error);
    // if (error?.status && error.status === 401) {
    //   window.location.href = '/';
    // }
    return Promise.reject(error);
  }
);

// export function setAxiosToken(token: string) {
//   zohoRequester.defaults.headers.common.Authorization = 'Bearer ' + token;
// }

// export function removeAxiosToken() {
//   zohoRequester.defaults.headers.common.Authorization = '';
// }
export default zohoRequester;
