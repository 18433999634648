import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ProductType } from '../../models';
import { productTypeService } from '../../services';
import { Loading } from '../../types';

interface ProductTypeState {
  productTypes: ProductType[];
  productTypesLoading: Loading;
}

const name = 'productType';
const initialState: ProductTypeState = {
  productTypes: [],
  productTypesLoading: 'idle'
};

export const fetchProductTypes = createAsyncThunk(
  `${name}/list-management-product-types`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await productTypeService.getProductTypes(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const productTypeSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductTypes.pending, (state) => {
        state.productTypesLoading = 'pending';
      })
      .addCase(fetchProductTypes.fulfilled, (state, action) => {
        state.productTypesLoading = 'idle';
        state.productTypes = action.payload.data ?? [];
      })
      .addCase(fetchProductTypes.rejected, (state) => {
        state.productTypesLoading = 'idle';
      });
  }
});

export const selectProductTypes = (state: RootState) =>
  state.productType.productTypes;
export const selectProductTypesLoading = (state: RootState) =>
  state.productType.productTypesLoading;

export default productTypeSlice.reducer;
