import { PostgrestSingleResponse } from '@supabase/supabase-js';
import supabaseRequest from '../requesters/supabase.request';
import { Sale } from 'models';
const saleService = {
  getSales: async (query: any): Promise<PostgrestSingleResponse<Sale[]>> =>
    await supabaseRequest
      .from('sales')
      .select()
      .filter('deleted_at', 'is', null)
};
export default saleService;
