import { PostgrestSingleResponse } from '@supabase/supabase-js';
import supabaseRequest from '../requesters/supabase.request';
const prestationService = {
  getPrestations: async (query: any): Promise<PostgrestSingleResponse<any[]>> =>
    await supabaseRequest
      .from('prestations')
      .select()
      .filter('deleted_at', 'is', null)
};
export default prestationService;
