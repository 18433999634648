import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Sale } from '../../models';
import { saleService } from '../../services';
import { Loading } from '../../types';

interface SaleState {
  sales: Sale[];
  salesLoading: Loading;
}

const name = 'sale';
const initialState: SaleState = {
  sales: [],
  salesLoading: 'idle'
};

export const fetchSales = createAsyncThunk(
  `${name}/list-management-sales`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saleService.getSales(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saleSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSales.pending, (state) => {
        state.salesLoading = 'pending';
      })
      .addCase(fetchSales.fulfilled, (state, action) => {
        state.salesLoading = 'idle';
        state.sales = action.payload.data ?? [];
      })
      .addCase(fetchSales.rejected, (state) => {
        state.salesLoading = 'idle';
      });
  }
});

export const selectSales = (state: RootState) => state.sale.sales;
export const selectSalesLoading = (state: RootState) => state.sale.salesLoading;

export default saleSlice.reducer;
