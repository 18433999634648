import { Row, Col, Form, Input, Card, Space, FormInstance } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { Contact } from '../models';
import { useEffect } from 'react';
const { TextArea } = Input;

const Logistique = ({
  type,
  form,
  data,
  contactData,
  disabled
}: {
  type: 'estimate' | 'order';
  form: FormInstance;
  data?: any | null;
  contactData?: Contact | null;
  disabled?: boolean
}) => {
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        cf_objet:
          type === 'estimate' && data
            ? data.custom_field_hash?.cf_objet ?? ''
            : data?.custom_field_hash?.cf_objet_devis ?? ''
      });
    }
  }, []);
  return (
    <>
      <section className='mt-2 section logistique'>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className='mb-6'>
            <Space
              direction='vertical'
              style={{ width: '100%' }}
              className='logistique__alert'>
              <WarningOutlined
                style={{
                  color: '#BD2D2C',
                  fontWeight: '600px',
                  fontSize: '32px'
                }}
              />
              <strong>
                Encours disponible:
                {contactData && contactData?.cf_encours_disponible
                  ? ' ' +
                    contactData.cf_encours_disponible
                      .toString()
                      .replace('€', '')
                      .replace(/\./g, ' ')
                  : ' 0 '}{' '}
                €
              </strong>
            </Space>
            <Form.Item
              label='Object du document'
              initialValue={
                type === 'estimate' && data
                  ? data.custom_field_hash?.cf_objet ?? ''
                  : data?.custom_field_hash?.cf_objet_devis ?? ''
              }
              name='cf_objet'>
              <TextArea
                disabled={disabled}
                className='logistique__document-textarea'
                placeholder='Des détails à rajouter concernant cette commande ?'
                allowClear
                autoSize={{ minRows: 6, maxRows: 6 }}
                value={
                  type === 'estimate' && data
                    ? data.custom_field_hash?.cf_objet ?? ''
                    : data?.custom_field_hash?.cf_objet_devis ?? ''
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className='mb-6'>
            <Row gutter={[24, 0]} className=''>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-6 '>
                <Card
                  bordered={false}
                  className='logistique__card'
                  title='Demande commerciale'>
                  {data && data?.custom_field_hash?.cf_demande_commerciale
                    ? data.custom_field_hash.cf_demande_commerciale
                        .split('\n')
                        .map((subStr: string, index: number) => (
                          <p key={index}>{subStr}</p>
                        ))
                    : ''}
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mb-6 '>
                <Card
                  bordered={false}
                  className='logistique__card'
                  title='Réponse logistique'>
                  {data && data?.custom_field_hash?.cf_r_ponse_logistique
                    ? data.custom_field_hash.cf_r_ponse_logistique
                        .split('\n')
                        .map((subStr: string, index: number) => (
                          <p key={index}>{subStr}</p>
                        ))
                    : ''}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
};
export default Logistique;
